
export const checkForOverlaps = (checks) => {
    //console.log(`checking for overlaps...`)
    //console.log(checks)
    let areThereAnyOverlaps = false
    let overlapMessage = ''
    const timestamps = []
    const startTime = new Date("2023-11-20T01:00:00.000Z")

    for (let n = 0; n < 8; n++) {
        const date = new Date(startTime)
        date.setDate(date.getDate() + n)
        const checksForTheDay = startAndEndTimesForChecksOnDay(date, checks)

        for (let check of checksForTheDay) {
            timestamps.push({ timestamp: new Date(check.startTime), action: 'start', checkId: check.checkId })
            timestamps.push({ timestamp: new Date(check.endTime), action: 'end', checkId: check.checkId })
        }
    }
    timestamps.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
    let lastOneAction = null

    for (const timestamp of timestamps) {
        if (lastOneAction === timestamp.action && timestamp.action === 'start') {
            areThereAnyOverlaps = true
            overlapMessage = `A check starts on ${timestamp.timestamp.toLocaleString(navigator.language, { weekday: 'long' })} at ${formatNumberToTwoDigits(timestamp.timestamp.getHours())}:${formatNumberToTwoDigits(timestamp.timestamp.getMinutes())} - before the previous check has finished.`
        }
        lastOneAction = timestamp.action
    }
    return {
        areThereAnyOverlaps, overlapMessage
    }
}

function timeOnDayAt(date, hours, minutes) {
    const newDate = new Date(date)
    newDate.setHours(parseInt(hours))
    newDate.setMinutes(parseInt(minutes))
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate
}

function pythonDayNumber(jsDayNumber) {
    let pyDayNumber = (jsDayNumber - 1) % 7
    if (pyDayNumber == -1) {
        pyDayNumber = 6
    }
    return pyDayNumber
}

function startAndEndTimesForChecksOnDay(date, checks) {
    const dayNumber = pythonDayNumber(date.getDay())
    const checksToReturn = []

    Object.entries(checks).forEach(([check_id, check]) => {
        if (check.days.includes(dayNumber) || check.days.includes(`${dayNumber}`)) {
            let startTimeToday = timeOnDayAt(date, check.start.hours, check.start.minutes)
            let endTimeToday = timeOnDayAt(date, check.end.hours, check.end.minutes)

            if (endTimeToday < startTimeToday) {
                endTimeToday.setDate(endTimeToday.getDate() + 1)
            }

            checksToReturn.push({
                startTime: startTimeToday,
                endTime: endTimeToday,
                checkId: check.id
            })
        }
    })

    return checksToReturn
}

function formatNumberToTwoDigits(number) {
    return String(number).padStart(2, '0')
}

export const validateChecklistSettingsForm = (settingsFormFields, setSettingsFormErrors) => {
    let isFormValid = true
    //console.log('validating')
    const newSettingsFormErrors = {}

    // check if there is a valid checklist name
    if (
        !settingsFormFields['ChecklistName']
    ) {
        newSettingsFormErrors['ChecklistName'] = 'Please enter a name'
        isFormValid = false
    }

    // check if there is a valid frequency
    if (
        !settingsFormFields['Schedule'] ||
        !settingsFormFields['Schedule']['Frequency']
    ) {
        newSettingsFormErrors['Schedule'] = { ...newSettingsFormErrors['Schedule'] }
        newSettingsFormErrors['Schedule']['Frequency'] = 'Please select a frequency'
        isFormValid = false
    }

    // weekly frequency checks
    if (
        settingsFormFields['Schedule'] &&
        settingsFormFields['Schedule']['Frequency'] &&
        settingsFormFields['Schedule']['Frequency'] === 'WEEKLY'
    ) {


        if (settingsFormFields['Schedule']['CheckWindows'] && Object.keys(settingsFormFields['Schedule']['CheckWindows']).length > 0) {

            // if there are check periods, make sure all required fields are completed
            for (const thisCheckWindowId in settingsFormFields['Schedule']['CheckWindows']) {
                const thisCheckWindow = settingsFormFields['Schedule']['CheckWindows'][thisCheckWindowId]
                //console.log(thisCheckWindow)
                if (
                    thisCheckWindow.days.length === 0 ||
                    !thisCheckWindow.start.hours ||
                    !thisCheckWindow.start.minutes ||
                    !thisCheckWindow.end.hours ||
                    !thisCheckWindow.end.minutes ||
                    !thisCheckWindow.notification ||
                    !thisCheckWindow.CheckWindowName
                ) {
                    const errorFieldName = `CheckWindow-${thisCheckWindowId}`
                    newSettingsFormErrors[errorFieldName] = 'Please complete all required fields for this check period'
                    isFormValid = false
                }
            }

            // check for any overlaps
            const response = checkForOverlaps(settingsFormFields['Schedule']['CheckWindows'])
            if (response.areThereAnyOverlaps) {
                newSettingsFormErrors['CheckWindows'] = response.overlapMessage
                isFormValid = false
            }

        }
        else {
            // fail if there are no check windows added
            newSettingsFormErrors['CheckWindows'] = 'Please add at least one check period'
            isFormValid = false
        }
    }

    // setSettingsFormFields(settingsFormFields)
    setSettingsFormErrors(newSettingsFormErrors)
    return isFormValid
    //return false
}
