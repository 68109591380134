import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Spinner from "../ui-elements/Spinner"
import ListOfChecklists from '../checklists/ListOfChecklists'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'

type Props = {
}


function ChecklistDashboard({

}: Props) {

  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  return <div
    data-testid={'checklistdashborad'}
    className={`w-full flex flex-col gap-2 mt-2`}>

    {!tableData && <Spinner><p>Loading...</p></Spinner>}

    {tableData && tableData.Checklists && Object.keys(tableData.Checklists).length > 0 && <ListOfChecklists />}

    {tableData && (!tableData.Checklists || Object.keys(tableData.Checklists).length === 0) && <Card>
      <div className={`w-full flex flex-row flex-wrap items-center gap-4 justify-between`}>
        <p className={`text-sm`}>No checklists are currently set up.</p>

        <div className={`flex flex-row flex-wrap items-center gap-4 justify-between`}>
          <Button
            internalLinkUrl={`/checklists/presets/`}
            text={`Add checklist template`}
            fullwidth={false}
          />
          <Button
            internalLinkUrl={`/checklists/new/settings`}
            text={`Build a new checklist`}
            fullwidth={false}
          />
        </div>
      </div>
    </Card>}


  </div>
}
export default ChecklistDashboard