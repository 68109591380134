import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate } from "react-router-dom"
import Modal from './Modal'
import Button from '../formfields/Button'
import { subscribe } from "../utils/pubsub"
import Spinner from './Spinner'

type ObjectAny = {
  [key: string]: any
}

type Props = {
  loggedIn: Boolean | string
  logoutUrl: string
  setLoggedIn: React.Dispatch<React.SetStateAction<any>>
  setUserData: React.Dispatch<React.SetStateAction<any>>
  userData: ObjectAny | null
  tableData: ObjectAny | null
  currentOrganisation: string | null,
  switchCurrentOrganisation?: (organisationId: string) => void
  logoLink?: string
  isAccountSetUp: boolean | null
  sendMessageToWebsocket: (payload: string) => void
}

type LoggedInProps = {
  userData: ObjectAny | null
  tableData: ObjectAny | null
  handleLogout: () => void
  currentOrganisation: string | null,
  switchCurrentOrganisation?: (organisationId: string) => void
  setShowModal: React.Dispatch<React.SetStateAction<any>>,
  organisations: ObjectAny | null
  sendMessageToWebsocket: (payload: string) => void
  clearDemoData: () => void
  savingProgress: boolean
  setSavingProgress: React.Dispatch<React.SetStateAction<any>>
}

type MenuProps = {
  organisations: ObjectAny | null
  tableData: ObjectAny | null
  setShowMenu: React.Dispatch<React.SetStateAction<any>>,
  currentOrganisation: string | null,
  userData: ObjectAny | null
  setShowOrgsMenu: React.Dispatch<React.SetStateAction<any>>,
  showOrgsMenu: boolean
  switchCurrentOrganisation?: (organisationId: string) => void
  numberOfOrganisationsForThisUser: number,
  handleLogout: () => void
  sendMessageToWebsocket: (payload: string) => void
  clearDemoData: () => void
  savingProgress: boolean
  setSavingProgress: React.Dispatch<React.SetStateAction<any>>
}





function Menu({
  tableData,
  organisations,
  setShowMenu,
  currentOrganisation,
  userData,
  setShowOrgsMenu,
  showOrgsMenu,
  switchCurrentOrganisation,
  numberOfOrganisationsForThisUser,
  handleLogout,
  sendMessageToWebsocket,
  clearDemoData,
  savingProgress,
  setSavingProgress
}: MenuProps) {


  const navigate = useNavigate()



  return <div className={`fixed top-0 left-0 w-full flex flex-col items-center`}>


    <div className={`max-w-3xl px-4 w-full flex flex-col items-end`}>
      <div
        style={{
          width: '260px',
          top: '65px'
        }}
        className={`bg-brandblue fixed p-2 rounded flex flex-col gap-1 text-white text-xs z-50`}
        onMouseLeave={() => setShowMenu(false)}
      >

        {/* <div className={`md:hidden flex flex-col items-end`}>
          <img
            src='/close-x.svg'
            alt='Menu'
            className={`w-5`}
            onClick={() => setShowMenu(false)} />
        </div> */}


        <div className={`md:hidden flex flex-col pb-2 gap-1`}>
          <div className={`flex flex-row gap-2 items-center`}>
            <img src='/usericon.svg' className={`w-5`} alt='' />
            <p className={`font-bold`}>{userData!.name}</p>
          </div>
          {currentOrganisation && organisations && organisations[currentOrganisation] && <p className={``}>{organisations[currentOrganisation]}</p>}
        </div>

        {switchCurrentOrganisation && numberOfOrganisationsForThisUser > 1 && <>
          <div
            className={`font-bold text-sm px-3 py-2 rounded hover:opacity-90 bg-brandmidblue`}
          >

            <div className={`flex flex-row items-center gap-2`} onClick={() => setShowOrgsMenu(!showOrgsMenu)}>
              <p className={``}>Switch organisation</p>
              <img src='/arrow.svg' alt='' className={`h-3 ${showOrgsMenu && 'rotate-90'}`} />
            </div>


            {organisations && switchCurrentOrganisation && showOrgsMenu &&
              <div className={`pt-2 text-left`}>
                {
                  Object.keys(organisations).map((id, index) => {
                    return <div
                      className={`${currentOrganisation === id && 'hidden'} underline py-1 font-normal`}
                      key={index}
                      onClick={() => {
                        setShowMenu(false)
                        switchCurrentOrganisation(id)
                      }}>
                      {organisations[id]}
                    </div>
                  })
                }
              </div>}
          </div>
        </>}

         

        {  tableData && tableData.Organisation && tableData.Organisation.ActiveFeatures && Object.keys(tableData.Organisation.ActiveFeatures).includes('Checklists')     && <Button
          onClick={() => {
            setShowMenu(false)
            navigate('/checklists')
          }}
          text='Checklists'
          variant={`secondary`}
          align={'left'}
        /> }

        <Button
          onClick={() => {
            setShowMenu(false)
            navigate('/users')
          }}
          text='Users'
          variant={`secondary`}
          align={'left'}
        />
        <Button
          onClick={() => {
            setShowMenu(false)
            navigate('/loginoptions')
          }}
          text='Login options'
          variant={`secondary`}
          align={'left'}
        />
        <Button
          onClick={() => {
            setShowMenu(false)
            navigate('/payments')
          }}
          text='Payments'
          variant={`secondary`}
          align={'left'}
        />




        {tableData && tableData.Organisation && tableData.Organisation.IsDemoAccount && <Button
          onClick={() => {
            setShowMenu(false)
            clearDemoData()
          }}
          text='Clear demo data'
          variant={`warning`}
          align={'left'}
        />}



        {userData &&
          userData['custom:superadmin'] &&
          userData['custom:superadmin'] === `${true}` &&
          <>
            <Button
                  onClick={() => {
                setShowMenu(false)
                navigate('/stickerpacks')
              }}
              text='Sticker packs'
              variant={`warning`}
              align={'left'}
            />
            <Button
                  onClick={() => {
                setShowMenu(false)
                navigate('/superadmin')
              }}
              text='Superadmin'
              variant={`warning`}
              align={'left'}
            />
          </>}

        <Button
          onClick={handleLogout}
          text='Log out'
          variant={`secondary`}
          align={'left'}
        />


      </div>
    </div>



  </div>
}



function LoggedIn({
  tableData,
  userData,
  handleLogout,
  currentOrganisation,
  switchCurrentOrganisation,
  setShowModal,
  organisations,
  sendMessageToWebsocket,
  clearDemoData,
  savingProgress,
  setSavingProgress
}: LoggedInProps) {

  const [showMenu, setShowMenu] = useState(false)
  const [showOrgsMenu, setShowOrgsMenu] = useState(false)
  const numberOfOrganisationsForThisUser = organisations ? Object.keys(organisations).length : 0




  return <div className={`flex flex-row items-center gap-4`}>



    {showMenu && <Menu
      organisations={organisations}
      setShowMenu={setShowMenu}
      currentOrganisation={currentOrganisation}
      userData={userData}
      setShowOrgsMenu={setShowOrgsMenu}
      showOrgsMenu={showOrgsMenu}
      switchCurrentOrganisation={switchCurrentOrganisation}
      numberOfOrganisationsForThisUser={numberOfOrganisationsForThisUser}
      handleLogout={handleLogout}
      sendMessageToWebsocket={sendMessageToWebsocket}
      tableData={tableData}
      clearDemoData={clearDemoData}
      savingProgress={savingProgress}
      setSavingProgress={setSavingProgress}
    />
    }

    <div className={`hidden md:flex flex-col items-start justify-between gap-2 text-xs`}>
      <div className={`text-white flex flex-row items-center gap-2`}>
        <img src='/usericon.svg' className={`w-6`} alt='' />
        <p>{userData!.name}</p>
      </div>
      {currentOrganisation && organisations && organisations[currentOrganisation] && <p className={`text-white`}>{organisations[currentOrganisation]}</p>}

    </div>


    <div className={`block w-11 p-2 bg-brandblue`}>
      {showMenu ?
        <img
          src='/close-x.svg'
          alt='Menu'
          className={`w-full cursor-pointer`}
          onClick={() => setShowMenu(false)} /> :
        <img
          src='/hamburger.svg'
          alt='Menu'
          className={`w-full cursor-pointer`}
          onClick={() => setShowMenu(true)} />
      }
    </div>



  </div >
}

function Header({
  tableData,
  loggedIn,
  logoutUrl,
  setLoggedIn,
  setUserData,
  userData,
  currentOrganisation,
  switchCurrentOrganisation,
  logoLink,
  sendMessageToWebsocket,
  isAccountSetUp = null
}: Props) {


  const handleLogout = () => {
    setLoggedIn(false)
    setUserData(null)
    localStorage.removeItem('localIdToken')
    localStorage.removeItem('localAccessToken')
    window.location.href = logoutUrl
  }


  const [savingProgress, setSavingProgress] = useState(false)
  const organisations = userData ? JSON.parse(userData.organisationsForUser) : {}
  const navigate = useNavigate()
  const [showSwitchOrg, setShowModal] = useState(false)


  const clearDemoData = () => {
    setSavingProgress(true)
    const payload = JSON.stringify({
      action: 'clearDemoData'
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("demoDataCleared", (data: any) => {
      setSavingProgress(false)
      unsubscribe()
    })
  }




  return <header
    className={`z-50 flex flex-col items-center bg-brandblue`}
    style={{ background: `linear-gradient(rgba(22, 28, 46}, 0.8), rgba(22, 28, 46, 0.8)), url(/jessica-lewis-fJXv46LT7Xk-unsplash.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}
  >



    {showSwitchOrg && switchCurrentOrganisation && <Modal
      setShowModal={setShowModal}
      showCloseButton={true}
      closeButtonValue={false}
    >
      <p className={`$ text-brandblue  font-bold text-lg mb-3`}>Switch organisation</p>
      {
        Object.keys(organisations).map((id, index) => {
          return <div
            className={`${currentOrganisation === id && 'hidden'}
          border-none cursor-pointer px-3 py-2 rounded hover:opacity-90 mb-1 bg-gray-200 hover:bg-gray-300 text-xs`}
            key={index}
            onClick={() => {
              switchCurrentOrganisation(id)
              setShowModal(false)
            }}>
            {organisations[id]}
          </div>
        })
      }
    </Modal>}


    <div className={`max-w-3xl w-full p-4 flex flex-row items-center justify-between gap-5`}>
      <Link to={logoLink ? logoLink : '/dashboard'}>
        <img
          src={`/logo-white-no-strapline.svg`}
          alt='ChecQR Logo'
          className={`border-0`}
          style={{ width: '200px' }}
        />
      </Link>

      {loggedIn === true && userData &&
        <LoggedIn
          userData={userData}
          handleLogout={handleLogout}
          currentOrganisation={currentOrganisation}
          switchCurrentOrganisation={switchCurrentOrganisation}
          setShowModal={setShowModal}
          organisations={organisations}
          sendMessageToWebsocket={sendMessageToWebsocket}
          tableData={tableData}
          clearDemoData={clearDemoData}
          savingProgress={savingProgress}
          setSavingProgress={setSavingProgress}
        />
      }

    </div>

    {savingProgress && <Modal
      showCloseButton={true}
      setShowModal={setSavingProgress}
    ><Spinner><p>Clearing demo data...</p></Spinner>
    </Modal>}

  </header>
}
export default Header