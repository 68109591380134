import React, { useContext } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'
import Button from '../../formfields/Button'


type Props = {
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
    cancelInstructions: AnyFunction
    showCancelInstructionsButton: boolean
}

function QuestionInstructions({
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields,
    cancelInstructions,
    showCancelInstructionsButton
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const handleChange = (value: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        newFieldValues['QuestionInstructions'] = value
        setQuestionsFormFields(newFieldValues)
    }

    return <div className={`flex flex-col gap-2`}>
        <div className={`flex flex-row gap-2 items-center justify-between`}>
            <h3 className={`font-righteous text-brandblue text-lg`}>Question instructions: </h3>
            {showCancelInstructionsButton && <Button
                onClick={cancelInstructions}
                text={`Cancel`}
                variant={`gray`}
                fullwidth={false}
            />}
        </div>
        <input
            className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
            type='text'
            name={`QuestionInstructions`}
            value={questionsFormFields['QuestionInstructions'] || ''}
            onChange={(e) => handleChange(e.target.value)}

        />
        {questionsFormErrors['QuestionInstructions'] && <RedAlert alignment='left'>{questionsFormErrors['QuestionInstructions']}</RedAlert>}
    </div>
}



export default QuestionInstructions