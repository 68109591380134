
type Props = {
  questionAnswer: any
  isTemperature?: boolean
}

function FormatQuestionAnswer({
  questionAnswer,
  isTemperature = false
}: Props) {


  return <>
    {questionAnswer.Answer instanceof Array && <>[{questionAnswer.Answer && questionAnswer.Answer.map((option: string, index: number) => {
      return `${option}${index + 1 < questionAnswer.Answer.length ? ', ' : ''}`
    })}]</>}

    {typeof questionAnswer.Answer === 'string' ? `${questionAnswer.Answer}${isTemperature ? '°' : ''}` : ''}
  </>
}

export default FormatQuestionAnswer