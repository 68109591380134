export const startNewQuestion = (checklistId, setChecklistQuestionFields, navigate) => {
  const randomId = `question-${Date.now()}-${Math.ceil(Math.random() * 10000)}`
  const newChecklistQuestionFields = {
    "Id": randomId,
    "ChecklistId": checklistId
  }
  setChecklistQuestionFields(newChecklistQuestionFields)
  navigate(`/checklists/${checklistId}/questions/${randomId}/`)
}

