import React, { useState, useRef, useEffect, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'


const HorizontalScroll = () => {
  const scrollContainerRef: any = useRef(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)
  useEffect(() => {
    updateScrollStatus()
  }, [scrollPosition])

  const updateScrollStatus = () => {
    const scrollContainer: any = scrollContainerRef.current
    if (scrollContainer) {
      setCanScrollLeft(scrollContainer.scrollLeft > 0)
      setCanScrollRight(scrollContainer.scrollLeft < scrollContainer.scrollWidth - scrollContainer.clientWidth)
    }
  }
  const handleScroll = (scrollAmount: number) => {
    if ((scrollAmount < 0 && canScrollLeft) || (scrollAmount > 0 && canScrollRight)) {
      const newScrollPosition = scrollPosition + scrollAmount
      scrollContainerRef!.current!.scrollLeft = newScrollPosition
      setScrollPosition(newScrollPosition)
    }
  }

  return <div>
    <div className={`w-96 border relative overflow-hidden`} ref={scrollContainerRef}>

      <table><tbody><tr>
        <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>
        <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>  <td>dfgdf</td>
      </tr></tbody></table>

    </div>
    <div className="scroll-buttons">
      <button onClick={() => handleScroll(-50)} disabled={!canScrollLeft}>Scroll Left</button>
      <button onClick={() => handleScroll(50)} disabled={!canScrollRight}>Scroll Right</button>
    </div>
  </div>
}

export default HorizontalScroll
