import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate, useParams } from "react-router-dom"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import { subscribe } from "../utils/pubsub"
import DeleteButton from '../formfields/DeleteButton'
import { validateForm } from '../utils/validateForm'
import Button from '../formfields/Button'
import Radio from '../formfields/Radio'
import Text from '../formfields/Text'
import Card from '../ui-elements/Card'
import SubNavigation from '../shared-components/SubNavigation'

type ObjectAny = {
    [key: string]: any
}


type Props = {

}

type AddOrEditProps = {

}

type FridgeCardProps = {

}

function AddEditFridge({

}: AddOrEditProps) {

    const {
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket
    } = useContext(GlobalContext)

    const initialFormData = {
        "AssetName": { "value": '', "required": true, "type": "text" },
        "HasDigitalReadout": { "value": '', "required": true, "type": "radio" },
        "DigitsBeforeDecimal": { "value": '', "required": false, "type": "number" },
        "DigitsAfterDecimal": { "value": '', "required": false, "type": "number" },
        // "ProfileId": { "value": '', "required": false, "type": "dropdown" },
        "IsActive": { "value": '', "required": false, "type": "radio" },
        // "GroupId": { "value": '', "required": false, "type": "dropdown" }
    }
    const { fridgeId } = useParams()
    const navigate = useNavigate()
    const [formFields, setFormFields] = useState<ObjectAny>(initialFormData)
    const [savingProgress, setSavingProgress] = useState(false)
    useEffect(() => {
        if (fridgeId && tableData!.Assets[fridgeId]) {
            const newFormFields = { ...formFields }
            Object.keys(initialFormData).forEach((key) => {
                newFormFields[key] = { ...newFormFields[key], "value": tableData!.Assets[fridgeId][key] }
            })
            setFormFields(newFormFields)
        }
    }, [])


    const saveItem = () => {
        setSavingProgress(true)
        const values = { ...formFields }
        values['AssetType'] = 'Fridge'

        const payload = JSON.stringify({
            Id: fridgeId,
            action: fridgeId ? "updateItemRequest" : "addItemRequest",
            tableName: 'Assets',
            values: values
        })

        // console.log(JSON.stringify(values))
        sendMessageToWebsocket(payload)

        const unsubscribeUpdate = subscribe(fridgeId ? "tableUpdateItemModified" : "tableUpdateItemAdded", data => {
            setSavingProgress(false)
            unsubscribeUpdate()
            navigate('/dashboard')
        })
    }

    const handleChange = (key: string, value: any) => {
        const newFieldValues: ObjectAny = { ...formFields }
        newFieldValues[key] = { ...newFieldValues[key], 'value': value }
        setFormFields(newFieldValues)
    }


    return <div className={`flex flex-col items-center`}>

        <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>

            <h2 className={`text-3xl  font-bold font-righteous text-brandblue`}>{fridgeId ? 'Edit fridge' : 'Add fridge'}</h2>

            {savingProgress && <Modal
                showCloseButton={true}
                setShowModal={setSavingProgress}
            ><Spinner><p>Updating...</p></Spinner>
            </Modal>}

            <Card fullwidth={true}>
                <div
                    className={`w-full flex flex-col gap-5`}
                >
                    <div>
                        <p className={`font-bold text-left`}>Name:</p>
                        <input
                            type="text"
                            onChange={(e) => handleChange('AssetName', e.target.value)}
                            value={formFields['AssetName'] ? formFields['AssetName']['value'] : ''}
                            className={`w-full mb-2 bg-gray-200 border border-gray-300 text-gray-900 text-left rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 p-2.5`}
                        />

                        {formFields['AssetName'] && formFields['AssetName']['isValid'] === false && <p className={`text-xs text-red-700`}>Please enter a valid name</p>}
                    </div>

                    <div>
                        <p className={`font-bold text-left`}>Digital readout:</p>
                        <Radio
                            formFields={formFields}
                            fieldName={`HasDigitalReadout`}
                            labelText={'Does this fridge or freezer have a digital display?'}
                            handleChange={handleChange}
                        />
                    </div>




                    {formFields['HasDigitalReadout'] && formFields['HasDigitalReadout']['value'] &&
                        <div className={`flex flex-col gap-2`}>
                            <p className={`font-bold text-left`}>Digits:</p>

                            <div className={`flex flex-row gap-2`}>

                                <div className={`flex-1 flex flex-col gap-2`}>
                                    <p className={`text-sm`}>Before decimal:</p>
                                    <select
                                        onChange={(e) => handleChange('DigitsBeforeDecimal', e.target.value)}
                                        value={formFields['DigitsBeforeDecimal'] ? formFields['DigitsBeforeDecimal']['value'] : ''}
                                        className={`w-full mb-1 bg-gray-200 border border-gray-300 text-gray-900 text-left rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 p-2.5`}>
                                        <option value=''>Please select</option>
                                        <option value='1'>1</option>
                                        <option value='2'>2</option>
                                    </select>
                                    {formFields['DigitsBeforeDecimal'] && formFields['DigitsBeforeDecimal']['isValid'] === false && <p className={`text-xs text-red-700`}>Please select a number</p>}
                                </div>

                                <div className={`flex-1 flex flex-col gap-2`}>
                                    <p className={`text-sm`}>After decimal:</p>
                                    <select
                                        onChange={(e) => handleChange('DigitsAfterDecimal', e.target.value)}
                                        value={formFields['DigitsAfterDecimal'] ? formFields['DigitsAfterDecimal']['value'] : ''}
                                        className={`w-full mb-1 bg-gray-200 border border-gray-300 text-gray-900 text-left rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 p-2.5`}>
                                        <option value=''>Please select</option>
                                        <option value='0'>0</option>
                                        <option value='1'>1</option>
                                        <option value='2'>2</option>
                                    </select>
                                    {formFields['DigitsAfterDecimal'] && formFields['DigitsAfterDecimal']['isValid'] === false && <p className={`text-xs text-red-700`}>Please select a number</p>}
                                </div>

                            </div>
                        </div>}




                    <Button
                        onClick={(e: any) => validateForm(e, formFields, setFormFields, saveItem)}
                        text={'Save'}
                        variant={'primary'}
                        fullwidth={true}
                        size={'big'}
                    />


                </div>
            </Card>
        </div >
    </div >
}



export default AddEditFridge