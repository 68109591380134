
import React, { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { GlobalContext } from '../GlobalContext'
import Card from '../ui-elements/Card'
import Button from '../formfields/Button'
import { subscribe } from "../utils/pubsub"
import RedAlert from '../ui-elements/RedAlert'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import YellowAlert from '../ui-elements/YellowAlert'



type Props = {
}

type AddConnectionDialogProps = {
  mappedValues: any
  setMappedValues: React.Dispatch<React.SetStateAction<any>>
  selectedEvent: any
  setSelectedEvent: React.Dispatch<React.SetStateAction<any>>
  selectedList: any
  setSelectedList: React.Dispatch<React.SetStateAction<any>>
  columnsForLists: any
  setColumnsForLists: React.Dispatch<React.SetStateAction<any>>
  errorMessage: any
  setErrorMessage: React.Dispatch<React.SetStateAction<any>>
}

function SharepointConnector({
}: Props) {



  function AddConnectionDialog({
    mappedValues,
    setMappedValues,
    selectedEvent,
    setSelectedEvent,
    selectedList,
    setSelectedList,
    columnsForLists,
    setColumnsForLists,
    errorMessage,
    setErrorMessage
  }: AddConnectionDialogProps) {




    useEffect(() => {
      const unsubscribe = subscribe('returnSharepointColumnsForList', (data) => {
        setColumnsForLists((prevValue: any) => {
          let newValue: any = {}
          if (prevValue !== null) {
            newValue = { ...prevValue }
          }
          newValue[data['list_id']] = data['columns']
          return newValue
        })
      })
      return (() => {
        unsubscribe()
      })

    })

    function save_mapping(mapping_hash: any) {
      // to update an existing mapping mapping hash should include an 'id' attribute
      // for a new one leave it blank
      const save_mapping_request = {
        action: "integrations",
        subAction: 'getSharepointListsForSite',
        saveSharepointMapping: mapping_hash
      }
      sendMessageToWebsocket(save_mapping_request)

    }

    function column_mapping_options() {
      return <>
        <option value={''}>Choose...</option>
        <optgroup label="Answer">
          <option value="answer_timestamp">Date and time </option>
          <option value="value">value </option>
          <option value="alert_message">Reason alert was raised</option>
          <option value="checked_by_name">Name of person carrying out check</option>
          <option value="checked_by_email">Email address of person carrying out check</option>
          <option value="photo">photo </option>
          <option value="location">location </option>
          <option value="notes">notes </option>
          


        </optgroup>
        <optgroup label="Checklist">
          <option value="checklist_id">Checklist id</option>
          <option value="checklist_name">Checklist name</option>
          <option value="checklist_link">Link to checklist on dashboard</option>
        </optgroup>
        <optgroup label="Question">
          <option value="question_id">question id</option>
          <option value="question_text">question text</option>
        </optgroup>

      </>
    }

    function listSelected(event: any) {
      //console.log("List selected!")
      const selected_list = event.currentTarget.value
      setSelectedList(selected_list)
      setMappedValues({})
    }



    function siteSelected(event: any) {
      const selected_site = event.currentTarget.value
      //console.log(`Sharepoint site selected ${selected_site}`)
      setSelectedSite(selected_site)
      setSelectedList(null)
      setMappedValues({})
    }



    const saveMappingColumn = (columnId: string, value: string) => {
      const newMappedValues = { ...mappedValues }
      if (value) {
        newMappedValues[columnId] = { ...newMappedValues[columnId], 'column': value }
      } else {
        delete newMappedValues[columnId]['column']
      }
      setMappedValues(newMappedValues)
    }

    const saveMappingRequired = (columnId: string, checked: boolean) => {
      //console.log(`save required: ${columnId} checked: ${checked}`)
      const newMappedValues = { ...mappedValues }
      newMappedValues[columnId] = { ...newMappedValues[columnId], 'required': checked }
      setMappedValues(newMappedValues)
    }

    const saveIntegration = () => {
      if (selectedEvent && selectedList && selectedSite && Object.keys(mappedValues).length > 0) {
        setSavingProgress(true)
        setErrorMessage(``)
        const payload: ObjectStringKeyAnyValue = {
          action: "integrations",
          subAction: 'saveSharepointMapping',
          mapping: {
            selectedEvent: selectedEvent,
            selectedSite: selectedSite,
            selectedList: selectedList,
            mappedValues: mappedValues
          }
        }
        if (mappingToEdit) {
          payload['mapping']['id'] = mappingToEdit
        }
        sendMessageToWebsocket(JSON.stringify(payload))

        const unsubscribe = subscribe("returnSharepointMappingSaved", data => {
          // close modal if returned data contains a brand new id, or the id currently being edited
          if (!mappingToEdit || (mappingToEdit && data['mapping_id'] === mappingToEdit)) {
            setSavingProgress(false)
            setMappingToEdit(null)
            setShowAddConnection(false)
            setSelectedSite(null)
            setSelectedEvent(null)
            setSelectedList(null)
            setMappedValues({})
            getExistingMappings()
          }
          unsubscribe()
        })

      } else {
        setErrorMessage(`Please complete the form`)
      }
    }



    return <Card>
      <div className={`w-full flex flex-col gap-4`}>
        <h3 className={`font-righteous text-brandblue text-lg`}>Add or edit an event</h3>

        <div className={`flex flex-col gap-1`}>
          <p>Choose event to send</p>
          {tableData && tableData['Organisation'] && tableData['Organisation']['ChecklistActionOptions'] ?
            <select
              className={`w-full px-2 py-1 rounded border border-gray-400 text-sm`}
              onChange={(e) => setSelectedEvent(e.target.value)}
              defaultValue={selectedEvent || ''}
            >
              <option value={``}>Please select</option>
              {Object.keys(tableData['Organisation']['ChecklistActionOptions']).map((actionId: string, index: number) => {
                return <option key={index} value={actionId}>{actionId}</option>
              })}
            </select> :
            <RedAlert>No events for this organisation</RedAlert>
          }
        </div>

        <div className={`flex flex-col gap-1`}>
          <p>Choose sharepoint site</p>
          <select
            className={`w-full px-2 py-1 rounded border border-gray-400 text-sm`}
            onChange={siteSelected}
            defaultValue={selectedSite}
          >
            <option value={''} >No site selected</option>
            {(sharepointSites && sharepointSites['sites']) && sharepointSites['sites'].map((site: any, index: any) => {
              return <option
                key={index}
                value={site['id']}
              // selected={site['id'] == selectedSite}
              >
                {site['name']} ({site['webUrl']})
              </option>
            })}
          </select>
        </div>

        <div className={`flex flex-col gap-1`}>
          <p>Choose list on site</p>
          <select
            className={`w-full px-2 py-1 rounded border border-gray-400 text-sm`}
            onChange={listSelected}
            defaultValue={selectedList}
          >
            <option value={''} >No list selected</option>
            {sharepointLists && selectedSite && sharepointLists[selectedSite] && sharepointLists[selectedSite].map((listdata: any, index: number) => {
              return <option
                key={index}
                value={listdata['id']}
              // selected={selectedList == listdata['id']}
              >
                {listdata['displayName']} ({listdata['id']})
              </option>
            })
            }
          </select>
        </div>

        {//@ts-ignore
          columnsForLists && selectedList && columnsForLists![selectedList!] && <table>
            <thead>
              <tr>
                <th>Column name</th>

                <th>Use this column</th>
                <th>Data to send</th>
              </tr>
            </thead>
            <tbody>
              {//@ts-ignore
                columnsForLists && selectedList && columnsForLists![selectedList!] && columnsForLists![selectedList!].map((column: any, index: number) => {
                  if (!column['readOnly'] && column['columnGroup'] != '_Hidden') {
                    const last_column_key = Object.keys(column)[Object.keys(column).length - 1]

                    return <tr key={index}>
                      <td>{column['displayName']}</td>
                      <td>{column['required'] ? <>required</>
                        :
                        <input
                          name={`column_enabled[${column['name']}]`}
                          type="checkbox"
                          onChange={(e) => saveMappingRequired(column['name'], e.target.checked)}
                          defaultChecked={
                            mappedValues &&
                              mappedValues[column['name']] &&
                              mappedValues[column['name']].required ?
                              true : false
                          }
                        />}</td>
                      <td>

                        <select
                          onChange={(e) => saveMappingColumn(column['name'], e.target.value)}
                          className={`w-full px-2 py-1 rounded border border-gray-400 text-sm`}
                          name={`column_values[${column['name']}]`}
                          value={
                            mappedValues &&
                              mappedValues[column['name']] &&
                              mappedValues[column['name']].column ?
                              mappedValues[column['name']].column : ''}
                        >
                          {column_mapping_options()}
                        </select></td>

                    </tr>
                  }
                  return null
                })
              }
            </tbody>
          </table>
        }

        {errorMessage && <RedAlert>{errorMessage}</RedAlert>}

        <Button
          onClick={saveIntegration}
          text={`Save`}
          variant={`primary`}
          size={`big`}
        />
      </div>
    </Card>
  }

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields
  } = useContext(GlobalContext)

  const [savingProgress, setSavingProgress] = useState(false)
  const [sharepointMappings, setSharepointMappings] = useState<null | ObjectStringKeyAnyValue>(null)
  const [sharepointUserDetails, setSharepointUserDetails] = useState<any>({})
  const [sharepointSites, setSharepointSites] = useState<any>([])
  const [showAddConnection, setShowAddConnection] = useState<boolean>(false)
  const [sharepointLists, setSharepointLists] = useState<any>(null)
  const [selectedSite, setSelectedSite] = useState<any>(null)
  const [mappingToEdit, setMappingToEdit] = useState<string | null>(null)
  const [connectionState, setConnectionState] = useState<string>('unknown')
  const [mappedValues, setMappedValues] = useState<ObjectStringKeyAnyValue>({})
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null)
  const [selectedList, setSelectedList] = useState<string | null>(null)
  const [columnsForLists, setColumnsForLists] = useState<object | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()







  useEffect(() => {
    if (tableData && tableData['Organisation'] && tableData['Organisation']['tables']) {
      const table_keys = Object.keys(tableData['Organisation']['tables'])
      if (table_keys.indexOf('Integrations') === -1) {
        setConnectionState('integrationsTableMissing')
        return
      }
    }
    if (tableData && tableData['Organisation'])
      if (tableData['Organisation']['ActiveIntegrations'] && (tableData['Organisation']['ActiveIntegrations'].indexOf('sharepoint') !== -1)) {
        checkConnection()
      } else {
        setConnectionState('notConnected')
      }
  }, [tableData])

  useEffect(() => {
    if (connectionState == 'tokenObtained') {
      const request_sites = {
        action: "integrations",
        subAction: 'getSharepointSites'
      }
      sendMessageToWebsocket(request_sites)
    }
  }, [connectionState])

  useEffect(() => {


    const unsubscribeSites = subscribe('returnSharepointSites', data => {
      setSharepointSites(data)
      //console.log("🍓 got sharepoint sites")
      //console.log(data)
    })

    const unsubscribeLists = subscribe('returnSharepointListsForSite', data => {
      //console.log("updating lists")

      setSharepointLists((oldVal: any) => {
        //console.log("Updater function running")
        let newVal = null
        if (oldVal == null) {
          newVal = {}
        } else {
          newVal = { ...oldVal }
        }
        newVal[data['site_id']] = data['lists']
        return newVal
      })
      //console.log("🍓 got sharepoint sites")
      //console.log(data)
    })
    return (() => {
      unsubscribeSites()
      unsubscribeLists()
    })
  }, [])


  useEffect(() => {
    if (connectionState == 'tokenObtained') {
      const request_sites = {
        action: "integrations",
        subAction: 'getSharepointSites'
      }
      sendMessageToWebsocket(request_sites)
    }
  }, [connectionState])


  useEffect(() => {
    if (selectedSite) {
      //console.log('requesting lists for site')
      const request_lists = {
        action: "integrations",
        subAction: 'getSharepointListsForSite',
        selectedSite: selectedSite
      }
      sendMessageToWebsocket(request_lists)
    }
    if (selectedSite && selectedList) {
      //console.log('requesting columns for site and list')
      const request_columns = {
        action: "integrations",
        subAction: 'getColumnsForSharepointList',
        selectedSite: selectedSite,
        selectedList: selectedList
      }
      sendMessageToWebsocket(request_columns)
    }
  }, [selectedList, selectedSite])

  function checkConnection() {
    //console.log("Checking access token to sharepoint")
    setConnectionState('checkingToken')
    let checkSharepointConnectionMessage = JSON.stringify({
      action: "integrations",
      subAction: 'checkSharepointConnection'
    })

    const unsubscribe = subscribe("returnSharepointConnectionCheckResult", data => {
      //console.log("🧟‍♀️ got connection check results url")
      getExistingMappings()
      if (data['result']) {
        setConnectionState('tokenObtained')
        setSharepointUserDetails(data['details'])

      } else {
        setConnectionState('accessTokenError')
      }
      unsubscribe()
    })

    sendMessageToWebsocket(checkSharepointConnectionMessage)
  }

  function linkToSharepoint() {
    console.log("Link to sharepoint pressed")
    setConnectionState('connecting')
    let requestAzureLoginUrl = JSON.stringify({
      action: "integrations",
      subAction: 'getSharepointLoginUrl'
    })

    const unsubscribe = subscribe("returnSharepointLoginUrl", data => {
      console.log("🧟‍♀️got login url")
      window.location.href = data['url']
      unsubscribe()
    })

    sendMessageToWebsocket(requestAzureLoginUrl)
  }


  function getExistingMappings() {
    //console.log("💠 getting existing mappings")
    let payload = JSON.stringify({
      action: "integrations",
      subAction: 'getSharepointMappings'
    })

    const unsubscribe = subscribe("returnSharepointMappings", data => {
      //console.log('🦺')
      setSharepointMappings(data.mappings)
      unsubscribe()
    })

    sendMessageToWebsocket(payload)
  }

  function getAdminConsent() {
    console.log("Get admin consent sharepoint pressed")
    setConnectionState('connecting')
    let requestAzureLoginUrl = JSON.stringify({
      action: "integrations",
      subAction: 'getSharepointLoginUrl'
    })

    const unsubscribe = subscribe("returnSharepointLoginUrl", data => {
      console.log("🧟‍♀️got login url")
      window.location.href = data['admin_consent_url']
      unsubscribe()
    })

    sendMessageToWebsocket(requestAzureLoginUrl)
  }


  function logOut() {
    console.log("Get admin consent sharepoint pressed")
    setConnectionState('connecting')


    let requestDeleteAccessToken = JSON.stringify({
      action: "integrations",
      subAction: 'deleteSharepointAccessToken'
    })


    const unsubscribe = subscribe("returnSharepointAccesstokenDeleted", data => {

      let requestAzureLoginUrl = JSON.stringify({
        action: "integrations",
        subAction: 'getSharepointLoginUrl'
      })
      const inner_unsubscribe = subscribe("returnSharepointLoginUrl", data => {
        console.log("Log out url")
        console.log(data['logout_url'])
        window.location.href = data['logout_url']
        inner_unsubscribe()
      })
      sendMessageToWebsocket(requestAzureLoginUrl)
      unsubscribe()
    })
    sendMessageToWebsocket(requestDeleteAccessToken)


  }

  const editMapping = (id: string, thisMapping: ObjectStringKeyAnyValue) => {
    setMappingToEdit(id)
    setShowAddConnection(true)
    setSelectedEvent(thisMapping.selectedEvent)
    setSelectedSite(thisMapping.selectedSite)
    setSelectedList(thisMapping.selectedList)
    setMappedValues(thisMapping.mappedValues)
  }



  return <div className={`w-full flex flex-col gap-4 items-center`}>
    <div className={`w-full flex flex-col gap-2 max-w-3xl p-4`}>

      <Card>
        <h3 className={`font-righteous text-brandblue text-lg`}>Sharepoint Connection</h3>


        {(connectionState == 'integrationsTableMissing') && <RedAlert>This organisation has no Integrations table, please contact support</RedAlert>}
        {(connectionState == 'notConnected') && <p>This account has not been linked to sharepoint
          <Button text={"Link now"} variant={'primary'} onClick={linkToSharepoint} />
        </p>}
        {(connectionState == 'accessTokenError') && <p>There is a problem - please log in to sharepoint again
          <Button text={"Link now"} variant={'primary'} onClick={linkToSharepoint} />

        </p>}

        {(connectionState === 'unknown' || connectionState === 'checkingToken') && <Spinner><p>Connecting...</p></Spinner>}

        {(connectionState === 'connecting') && <Spinner><p>Redirecting...</p></Spinner>}

        {(connectionState == 'tokenObtained') && <div className={`w-full flex flex-col gap-2 items-start`}>
          <p>✅ authorised by {sharepointUserDetails['mail']}</p>
          <Button
            text={"Cancel authorisation"}
            variant={'primary'}
            onClick={logOut}
            fullwidth={false}
          />
        </div>}
      </Card>
      {(connectionState == 'tokenObtained') && <div className={`flex flex-col gap-2`}>


        {sharepointSites && <Card>
          <div className={`w-full flex flex-row justify-between items-center gap-4`}>
            <h3 className={`font-righteous text-brandblue text-lg`}>Event mappings</h3>
            <Button
                  text={"Add"}
              variant={'primary'}
              onClick={() => {
                setShowAddConnection(true)
                setSelectedSite(null)
                setSelectedEvent(null)
                setSelectedList(null)
                setMappedValues({})
              }}
              fullwidth={false}
            />
          </div>


          {sharepointMappings && Object.keys(sharepointMappings).length > 0 ?
            <div className={`w-full flex flex-row flex-wrap gap-2`}>
              {Object.keys(sharepointMappings).map((id: string, index: number) => {
                const thisMapping = sharepointMappings[id]
                const sites = sharepointSites.sites ? sharepointSites.sites.filter((item: ObjectStringKeyAnyValue) => item.id === thisMapping['selectedSite']) : []
                const siteDisplayName = sites[0] ? sites[0]['displayName'] : 'unnamed site'
                return <div key={index}
                  onClick={() => editMapping(id, thisMapping)}
                  className={`
              ${mappingToEdit === id ? ' border-brandblue bg-brandblue text-white' : ' border-gray-300 bg-gray-200 '}
            border text-xs rounded px-2 py-1 hover:opacity-80 cursor-pointer
            `}>
                  <p className={`text-sm font-bold`}>{thisMapping['selectedEvent']}</p>
                  <p className={``}>Site: {siteDisplayName}</p>
                  <p className={``}>List: {thisMapping['selectedList']}</p>
                  <p className={`italic opacity-50`}>{id}</p>
                </div>
              })}
            </div> :
            <YellowAlert>No mappings have been set up yet</YellowAlert>
          }

        </Card>}





        {/* <div className={`text-xs`}>
          <p>Event: {selectedEvent} </p>
          <p>site: {selectedSite}</p>
          <p>list: {selectedList}</p>
          <p>{JSON.stringify(mappedValues).replaceAll(',', ', ')}</p>
        </div> */}


        {showAddConnection && <AddConnectionDialog
          mappedValues={mappedValues}
          setMappedValues={setMappedValues}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          columnsForLists={columnsForLists}
          setColumnsForLists={setColumnsForLists}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
        }

      </div>}
    </div>

    {savingProgress && <Modal
      showCloseButton={true}
      setShowModal={setSavingProgress}
    ><Spinner><p>Saving...</p></Spinner>
    </Modal>}

  </div>

}

export default SharepointConnector
