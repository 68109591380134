import React, { useContext } from 'react'
import { GlobalContext } from '../GlobalContext'

type Props = {
  formFields: { [key: string]: any }
  labelText: string
  handleChange: (key: string, value: any) => void
  fieldName: string,
  errorMessage?: string
  min?: number
  max?: number
  size?: 'big' | 'small',
  center?: boolean
}

function Number({
  formFields,
  labelText,
  handleChange,
  fieldName,
  errorMessage,
  min,
  max,
  size,
  center
}: Props) {

  const classString = `
  bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full
  ${center ? 'text-center' : 'text-left'}
  ${size === 'big' && 'text-lg px-5 py-3'}
  ${size === 'small' && 'text-xs px-2 py-1'}
  ${size !== 'small' && size !== 'big' && 'text-sm px-3 py-2'}
  `

  return <div className={`${center ? 'text-center' : 'text-left'} w-full flex flex-col gap-2`}>
    {labelText && <p className={`${center ? 'text-center' : 'text-left'}`}>{labelText}</p>}
    <input
      className={classString}
      type='number'
      name={fieldName}
      id={fieldName}
      min={min}
      max={max}
      value={formFields[fieldName] ? formFields[fieldName]['value'] : ''}
      onChange={(e) => handleChange(fieldName, e.target.value)}
    />
    {formFields[fieldName] && formFields[fieldName]['isValid'] === false && <p className={`${center ? 'text-center' : 'text-left'} text-xs text-red-700`}>
      {errorMessage ? errorMessage : 'Please enter a value'}
    </p>}
  </div>
}
export default Number