import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import Button from '../formfields/Button'
import Card from '../ui-elements/Card'

type Props = {
  title: string
  value: any
  link: string
  showEditButton?: boolean
}


function SummaryCheckWindow({
  title,
  value,
  link,
  showEditButton = true
}: Props) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields
  } = useContext(GlobalContext)

  const { checklistId } = useParams()
  const daysArray = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  const shortDaysArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

  const padOutZeros = (number: number | string) => {
    if (number === 0 || number === '0') {
      return '00'
    }
    return number
  }

  return <div className={`
  ${value && Object.keys(value).length > 0 ? ` bg-gray-300 ` : ` bg-red-100 `}
    flex flex-row w-full gap-4 justify-between items-center rounded px-2 py-1 text-sm
    `}
  // onClick={() => navigate(`${link}`)}
  >
    <div className={`flex flex-row gap-2 justify-start items-center`}>
      <p className={`font-bold`}>{title}:</p>
      {(!value || Object.keys(value).length === 0) && <img src={`/red-alert-icon.png`} className={`w-5 h-5`} alt={''} />}
      {value && Object.keys(value).map((checkWindowId, index) => {
        const thisCheckWindow = value[checkWindowId]
        return <div
          key={index}
          className={`border border-gray-400 rounded px-2 py-1 bg-white`}
        >

          <p className={``}>
            {thisCheckWindow && thisCheckWindow.CheckWindowName && typeof thisCheckWindow.CheckWindowName === 'string' ?
              thisCheckWindow.CheckWindowName :
              "Untitled check window"}
          </p>

          <p className={`text-xs text-gray-700`}>
            {padOutZeros(thisCheckWindow.start.hours)}:{padOutZeros(thisCheckWindow.start.minutes)} - {padOutZeros(thisCheckWindow.end.hours)}:{padOutZeros(thisCheckWindow.end.minutes)}</p>

          <p className={`text-xs text-gray-700`}>
            {thisCheckWindow.days.sort().map((day: number, index: number) => {
              let suffix = ','
              if (thisCheckWindow.days.length - 2 === index) suffix = ' &'
              if (thisCheckWindow.days.length - 1 === index) suffix = ''
              return ` ${shortDaysArray[day]}${suffix} `
            })}</p>
        </div>
      })}

    </div>

    {showEditButton && <Button
      internalLinkUrl={`${link}`}
      text={`Edit`}
      variant={`primary`}
      size={`small`}
      fullwidth={false}
    />}

  </div>
}
export default SummaryCheckWindow