import RedAlert from './RedAlert'

type Props = {
}

function AccessDenied({
}: Props) {

  return <div className={`w-full flex flex-col items-center justify-center`}>
    <div className={`max-w-3xl w-full p-4 flex flex-col items-center justify-center`}>
      <RedAlert fullwidth={true}>Sorry, you don't have access to this page.</RedAlert>
    </div>
  </div>
}
export default AccessDenied