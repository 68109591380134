export const validateForm = (
  event,
  formFields,
  setFormFields,
  submitFunction
) => {

  event && event.preventDefault()
  const newFieldValues = { ...formFields }

  //console.log(`validating form`)
  //console.log(formFields)

  for (const fieldName in newFieldValues) {
    const fieldType = newFieldValues[fieldName].type
    const fieldValue = newFieldValues[fieldName].value
    const fieldRequired = newFieldValues[fieldName].required
    const minNumber = newFieldValues[fieldName].minNumber
    const maxNumber = newFieldValues[fieldName].maxNumber

    // check each required field is valid
    //console.log(`field ${fieldName} is required? ${newFieldValues[fieldName].required} and type: ${fieldType}`)

    if (fieldRequired === true) {
      //console.log(`validating required field ${fieldName} of type ${fieldType}`)
      let isValid = true
      switch (fieldType) {
        case "text":
        case "dropdown":
          if (fieldValue && fieldValue.length > 0) {
            isValid = true
          } else {
            isValid = false
          }
          break

        case "radio":
          if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
            isValid = true
          } else {
            isValid = false
          }
          break

        case "number":
          if (!fieldValue || fieldValue.length === 0 || isNaN(fieldValue)) {
            isValid = false
            console.log('no')
          } else {
            if (minNumber && parseInt(fieldValue) < minNumber) {
              isValid = false
              console.log('no - less than min')
            }
            if (maxNumber && parseInt(fieldValue) > maxNumber) {
              isValid = false
              console.log('no - greater than max')
            }
          }

          break

        case "email":
          let emailRegex = new RegExp("^.+@.+")
          isValid = emailRegex.test(fieldValue)
          break
        default:
          isValid = fieldValue.length > 0
          break
      }
      newFieldValues[fieldName].isValid = isValid

      // special case to ensure max expected temperature is higher than min
      if (newFieldValues['ExpectedMinTemp'] && newFieldValues['ExpectedMaxTemp'] && parseInt(newFieldValues['ExpectedMinTemp'].value) >= parseInt(newFieldValues['ExpectedMaxTemp'].value)) {
        newFieldValues['ExpectedMinTemp'].isValid = false
        newFieldValues['ExpectedMaxTemp'].isValid = false
      }

    }
  }

  const fieldsWithErrors = Object.values(newFieldValues).filter((field, index) => {
    return field.isValid === false
  })

  //console.log(JSON.stringify(newFieldValues))

  setFormFields(newFieldValues)

  // submit the form if everything is valid
  if (fieldsWithErrors.length === 0) {
    //console.log('submit')
    submitFunction()
  }
}