import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Button from '../formfields/Button'

type Props = {
}

function SplashPage({}: Props) {

  const {
  } = useContext(GlobalContext)


  return <div
    className={`flex flex-col items-center justify-center flex-grow`}
    style={{
      background: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/qr-code-scan.jpeg)',
      backgroundSize: 'cover'
    }}
  >
    <div className={`p-5 bg-white rounded hover:opacity-90 flex flex-col gap-5 items-center`}>
      <h2
        className={`text-4xl font-bold text-brandblue `}
      >
        Welcome to ChecQR
        </h2>
      <Button
        text={'Go to dashboard'}
        internalLinkUrl={'/dashboard'}
        variant={'primary'}
        size={'big'}
      />
    </div>
  </div>
}
export default SplashPage