import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Number from '../formfields/Number'
import Button from '../formfields/Button'


type ObjectAny = {
  [key: string]: any
}

type Props = {
  tableData: ObjectAny | null,
  setTableData: React.Dispatch<React.SetStateAction<ObjectAny | null>>
  socket: WebSocket | null
  sendMessageToWebsocket: (payload: string) => void
  idToken: string | null
  accessToken: string | null
}

function SuperAdmin({
  tableData,
  setTableData,
  socket,
  sendMessageToWebsocket,
  idToken,
  accessToken,
}: Props) {



  const makeTables = () => {
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "makeTables",
      idToken: idToken,
      accessToken: accessToken
    })
    sendMessageToWebsocket(payload)
  }

  const initialFormData = {
    "NumberOfFridges": { "value": '', "required": true, "type": "number" },
    "NumberOfFreezers": { "value": '', "required": true, "type": "number" },
  }
  const [formFields, setFormFields] = useState<ObjectAny>(initialFormData)
  const [savingProgress, setSavingProgress] = useState(false)

  const handleChange = (key: string, value: any) => {
    const newFieldValues: ObjectAny = { ...formFields }
    newFieldValues[key] = { ...newFieldValues[key], 'value': value }
    setFormFields(newFieldValues)
  }


  const makeAuditSheet = async () => {
    setSavingProgress(true)
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "makeAuditSheet",
      values: formFields
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("makeAuditSheetResponse", data => {
      setSavingProgress(false)
      window.location.href = data.downloadUrl
      unsubscribe()
    })

  }

  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>
      <h2 className={`text-4xl font-bold text-brandblue `}>Superadmin stuff</h2>


      <div className={`w-full flex flex-row gap-2`}>
        <Button
          onClick={makeTables}
          text={'Make org tables'}
          size={'big'}
          variant={'primary'}
        />

<Button
          onClick={makeAuditSheet}
          text={'Make audit sheet'}
          size={'big'}
          variant={'primary'}
        />

      </div>
    </div>

    {savingProgress && <Modal
      showCloseButton={true}
      setShowModal={setSavingProgress}
    ><Spinner><p>Saving...</p></Spinner>
    </Modal>}
  </div>

}

export default SuperAdmin