// code from https://ralexanderson.com/blog/spreading-events-in-react-with-pubsub

import { v4 as uuidv4 } from 'uuid'

type AnyObject = {
  [key: string]: any
}

const topics: AnyObject = {}


export function subscribe(topic: string, fn: (data:any) => any) {
  if (!topics[topic]) topics[topic] = {}
  const id = uuidv4()
  topics[topic][id] = fn
  return () => {
    topics[topic][id] = null
    delete topics[topic][id]
  }
}

export function publish(topic: string | number, args: any) {
  if (!topics[topic]) return
  // console.log(`🐄 publishing message to topic ${topic}`)
  Object.values(topics[topic]).forEach((fn: any) => {
    if (fn) fn(args)
  })
}