import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Text from '../formfields/Text'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import EnabledDisabled from '../formfields/EnabledDisabled'
import Dropdown from '../formfields/Dropdown'
import Checkbox from '../formfields/Checkbox'
import SubNavigation from './SubNavigation'


type ObjectAny = {
  [key: string]: any
}

type Props = {

}

type FeatureCardProps = {
  title: string
  formFields: ObjectAny | null
  fieldName: string
  setFormFields: React.Dispatch<React.SetStateAction<any>>
  uncheckedApps: string[]
}


function FeatureCard({
  title,
  setFormFields,
  formFields,
  fieldName,
  uncheckedApps
}: FeatureCardProps) {




  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)






  const handleCheckbox = (key: string, value: any, checked: boolean) => {
    const newFieldValues: ObjectAny = { ...formFields }
    if (checked) {
      if (newFieldValues[key]) {
        if (newFieldValues[key].includes(value)) {
        } else {
          newFieldValues[key] = [...newFieldValues[key], value]
        }
      } else {
        newFieldValues[key] = [value]
      }
    } else {
      if (newFieldValues[key]) {
        if (newFieldValues[key].includes(value)) {
          newFieldValues[key] = newFieldValues[key].filter((item: any) => item !== value)
        }
      }
      if (newFieldValues[key].length < 1) {
        delete newFieldValues[key]
      }
    }
    setFormFields(newFieldValues)
  }






  return <Card>
    <h3 className={`text-xl font-bold text-brandblue`}>{title}</h3>



    <label><input
      className={`mr-1`}
      type={'checkbox'}
      name={title}
      value={'cognito'}
      checked={formFields && formFields[title] && formFields[title].includes('cognito') ? true : false}
      onChange={(e: any) => handleCheckbox(title, 'cognito', e.target.checked)}
    />Login with email</label>

    <label><input
      className={`mr-1`}
      type={'checkbox'}
      name={title}
      value={'guest'}
      checked={formFields && formFields[title] && formFields[title].includes('guest') ? true : false}
      onChange={(e: any) => handleCheckbox(title, 'guest', e.target.checked)}
    />Guest Logins</label>

    {uncheckedApps.includes(title) && <p className={`text-xs text-red-700`}>Please select at least one option</p>}

  </Card>

}


function LoginOptions({

}: Props) {


  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)


  const [savingProgress, setSavingProgress] = useState(false)
  const [formFields, setFormFields] = useState<ObjectAny>({})
  const [uncheckedApps, setUncheckedApps] = useState<Array<string>>([])

  useEffect(() => {
    setFormFields({})
  }, [currentOrganisation])


  useEffect(() => {
    if (tableData && tableData.Organisation && tableData.Organisation.loginOptions) {
      const newFieldValues: ObjectAny = { ...formFields }
      Object.keys(tableData.Organisation.loginOptions).forEach((optionName: string) => {
        tableData.Organisation.loginOptions[optionName].forEach((optionValue: string) => {
          if (newFieldValues[optionName]) {
            if (newFieldValues[optionName].includes(optionValue)) {
            } else {
              newFieldValues[optionName] = [...newFieldValues[optionName], optionValue]
            }
          } else {
            newFieldValues[optionName] = [optionValue]
          }

        })
      })
      setFormFields(newFieldValues)
    }
  }, [tableData])





  const validateLoginForm = () => {
    let newUncheckedApps: string[] = []
    if (tableData && tableData.Organisation && tableData.Organisation.ActiveFeatures) {
      Object.keys(tableData.Organisation.ActiveFeatures).forEach((featureName: string) => {
        if (formFields[featureName] && formFields[featureName].length > 0) {
          //console.log(`${featureName} has at least one option`)
        } else {
          newUncheckedApps.push(`${featureName}`)
        }
      })
    }
    setUncheckedApps(newUncheckedApps)
    if (newUncheckedApps.length === 0) {
      saveData()
    }
  }


  const saveData = () => {
    setSavingProgress(true)
    const values = { ...formFields }
    const payload = JSON.stringify({
      action: "loginPreferences",
      values: values
    })
    sendMessageToWebsocket(payload)

    const unsubscribeUpdate = subscribe("loginPreferencesResponse", data => {
      setSavingProgress(false)
      unsubscribeUpdate()
    })
  }



  return <div className={`flex flex-col items-center`}>
    <SubNavigation
      appName={'fridges'}
      fullWidthStrip={true}
    />
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>
      <h2 className={`text-3xl  font-bold font-righteous font-brandblue`}
      >Login Options</h2>


      {tableData && tableData.Organisation ? <div
        className={`w-full flex flex-col items-start gap-5`}>

        {!tableData.Organisation.ActiveFeatures && <p>This organisation has no active features</p>}

        {Object.keys(tableData.Organisation.ActiveFeatures).map((featureName, index) => {
          return <FeatureCard
            key={index}
            title={featureName}
            setFormFields={setFormFields}
            formFields={formFields}
            fieldName={''}
            uncheckedApps={uncheckedApps}
          />
        })}

      </div>
        :
        <Spinner><p>Loading...</p></Spinner>}


      <Button
        text={'Save preferences'}
        onClick={(event: any) => validateLoginForm()}
        size={'big'}
        fullwidth={false}
      // disabled={canSubmit ? false : true}
      // variant={canSubmit ? 'primary' : 'gray'}
      />



      {/* <p className={`text-xs`}>{JSON.stringify(formFields).replaceAll(',', ', ')}</p> */}

      {savingProgress && <Modal
        showCloseButton={true}
        setShowModal={setSavingProgress}
      ><Spinner><p>Saving...</p></Spinner>
      </Modal>}


    </div>
  </div>
}
export default LoginOptions