import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import DeleteButton from '../formfields/DeleteButton'

type Props = {
  question: ObjectStringKeyAnyValue
  showEditButton?: boolean,
  questionId: string
  savingProgress: boolean
  setSavingProgress: React.Dispatch<React.SetStateAction<any>>
  isFirst?: boolean
  isLast?: boolean
}


function IndividualQuestion({
  question,
  questionId,
  showEditButton = true,
  savingProgress,
  setSavingProgress,
  isFirst,
  isLast
}: Props) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields
  } = useContext(GlobalContext)

  const { checklistId } = useParams()

  const deleteQuestion = (questionId: string) => {
    if (questionId) {
      setSavingProgress(true)
      const payload = JSON.stringify({
        action: "checklists",
        subAction: "deleteQuestion",
        questionId: questionId
      })
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("checklistQuestionDeletedResponse", data => {
        setSavingProgress(false)
        unsubscribe()
      })
    }
  }


  const changeOrder = (questionId: string, direction: string) => {
    if (questionId) {
      setSavingProgress(true)
      const payload = JSON.stringify({
        action: "checklists",
        subAction: "changeQuestionOrder",
        questionId: questionId,
        checklistId: checklistId,
        direction: direction
      })
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("changeQuestionOrderResponse", data => {
        setSavingProgress(false)
        unsubscribe()
      })
    }
  }



  return <div className={`
  ${question['QuestionFormat'] ? ` bg-gray-300 ` : ` bg-red-100 `}
    flex flex-row w-full gap-4 justify-between items-center rounded px-2 py-1 text-sm
    `}
  >
    <div className={`flex flex-col gap-2 justify-start items-center`}>
      <div>




        <p className={`font-bold`}>
          {question['QuestionFormat'] === 'FridgeTemperature' ?
            `Temperature check for fridge${question['FridgeId'] ? `: #${question['FridgeId']}` : ''}` :
            question['QuestionText']}

          {/* : <span className={`font-normal italic text-xs text-gray-600`}> ({questionId})</span> */}
        </p>

        {question['QuestionFormat'] !== 'FridgeTemperature' &&
          <p>{question['QuestionFormat']}</p>}

        {question['QuestionInstructions'] &&
          <p>Instructions: {question['QuestionInstructions']}</p>}

        {question['QuestionOptions'] && question['QuestionOptions'].length > 0 &&
          <div className={`flex flex-row flex-wrap gap-1`}>
            Options:
            {question['QuestionOptions'].map((option: string, index: number) => {
              return <p key={index}>{option}{(index + 1) < question['QuestionOptions'].length && ','}</p>
            })}
          </div>}

        {question['AnswerActions'] && Object.keys(question['AnswerActions']).length > 0 &&
          <p>{Object.keys(question['AnswerActions']).length} answer action{Object.keys(question['AnswerActions']).length > 1 ? 's' : ''}</p>}

      </div>
      {!question['QuestionFormat'] &&
        <img src={`/red-alert-icon.png`} className={`w-5 h-5`} alt={''} />}
    </div>


    {showEditButton && <div className={`flex flex-row gap-2 justify-start items-center`}>

      {/* {question['QuestionOrder'] ? question['QuestionOrder'] : '??'} */}

      <button
        className={`${isFirst && 'opacity-50'}`}
        disabled={isFirst ? true : false}
        onClick={() => changeOrder(questionId, 'up')}
      >⬆️</button>
      <button
        className={`${isLast && 'opacity-50'}`}
        disabled={isLast ? true : false}
        onClick={() => changeOrder(questionId, 'down')}
      >⬇️</button>



      <DeleteButton
        deleteFunction={deleteQuestion}
        functionParams={questionId}
        size={`small`}
      />
      <Button
        internalLinkUrl={`questions/${questionId}/`}
        text={`Edit`}
        variant={`primary`}
        size={`small`}
        fullwidth={false}
      />
    </div>}

  </div>
}
export default IndividualQuestion