import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import { getDate } from '../utils/getDate'
import YellowAlert from '../ui-elements/YellowAlert'
import Spinner from '../ui-elements/Spinner'
import FormatQuestionAnsweredBy from '../FormatQuestionAnsweredBy'



type Props = {
  answerNotes: ObjectStringKeyAnyValue
}

function ListAnswerNotes({
  answerNotes
}: Props) {



  const {
    currentOrganisation,
  } = useContext(GlobalContext)

const [sortedNotes, setSortedNotes] = useState<Array<any> | null>(null)

useEffect(() => {
  sortKeysByTimestamp()
}, [answerNotes])

const sortKeysByTimestamp = () =>  {
  const entries = Object.entries(answerNotes)
  entries.sort((a, b) => {
      return b[1].Timestamp - a[1].Timestamp;
  })
  const sortedKeys = entries.map(([key]) => key);
  setSortedNotes(sortedKeys)
}

  return <div className={`flex flex-col gap-2`}>
    <h4 className={`text-xl font-righteous text-brandblue`}>Notes:</h4>

    {!sortedNotes && <Spinner>Loading...</Spinner>}

    {sortedNotes && <div className={`flex flex-col gap-2`}>
      {sortedNotes.map((noteId: string, index: number) => {
        const thisNote = answerNotes[noteId]
        return <YellowAlert key={index}>
          <div className={`flex flex-col gap-2 items-center justify-center`}>
            {thisNote.PhotoNoteFileName && typeof thisNote.PhotoNoteFileName === 'string' && <img
              src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${currentOrganisation}/${thisNote.PhotoNoteFileName}`}
              alt=''
              className={`rounded bg-gray-300 min-w-12 min-h-12 max-w-36 max-h-36`}
            />}
            {thisNote.AnswerNote && <p>"{thisNote.AnswerNote}"</p>}
            <p className={`text-xs italic text-gray-600`}>Added by <FormatQuestionAnsweredBy questionAnswer={thisNote} /> on  {getDate(thisNote.Timestamp, 'formattedDateAndTime')}</p>
          </div>
        </YellowAlert>
      })}
    </div>}
  </div >
}
export default ListAnswerNotes