import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import SummaryItem from './SummaryItem'
import SummaryCheckWindow from './SummaryCheckWindow'
import IndividualQuestion from './IndividualQuestion'
import EnabledDisabled from '../formfields/EnabledDisabled'
import { subscribe } from '../utils/pubsub'
import Spinner from '../ui-elements/Spinner'

type Props = {
  thisChecklist: ObjectStringKeyAnyValue
  fullwidth?: boolean
  // savingProgress: boolean
  // setSavingProgress: React.Dispatch<React.SetStateAction<any>>
}

function EnableOrDisableChecklist({
  thisChecklist,
  fullwidth = false
  // savingProgress,
  // setSavingProgress
}: Props) {


  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields
  } = useContext(GlobalContext)

  const [savingProgress, setSavingProgress] = useState(false)

  const toggleChecklistActive = () => {
    // console.log('🚚 toggling....')
    // console.log(thisChecklist)
    if (thisChecklist) {

      const newChecklistSetupFields = { ...thisChecklist }
      if (newChecklistSetupFields['isChecklistActive']) {
        newChecklistSetupFields['isChecklistActive'] = false
      } else {
        newChecklistSetupFields['isChecklistActive'] = true
      }
      setChecklistSetupFields(newChecklistSetupFields)

      setSavingProgress(true)
      const payload = JSON.stringify({
        action: "checklists",
        subAction: "addOrUpdate",
        payload: {
          "Id": thisChecklist['Id'],
          "isChecklistActive": newChecklistSetupFields['isChecklistActive']
        }
      })
      // console.log(payload)
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("tableUpdateItemModified", data => {
          // console.log(data)
          // console.log(`table update modified. Component ID: ${thisChecklist['Id']} and data ID: ${data.newRecord['Id']}`)
        if (
          data.newRecord &&
          data.newRecord['Id'] &&
          data.newRecord['Id'] === thisChecklist['Id']
          ) {
          setSavingProgress(false)
          unsubscribe()
        }
      })
    }
  }


  return <div className={`${fullwidth && 'w-full'} flex flex-row justify-between gap-4 items-center`}>
    <p className={`text-xs`}>Checklist enabled?</p>
    {savingProgress && <Spinner size={'small'}></Spinner>}
    {!savingProgress && <EnabledDisabled
      handleChange={toggleChecklistActive}
      fieldName={'isChecklistActive'}
      enabled={thisChecklist['isChecklistActive'] === true ? true : false}
    />}
  </div>
}
export default EnableOrDisableChecklist