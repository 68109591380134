import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../../GlobalContext'
import Button from '../../formfields/Button'
import Card from '../../ui-elements/Card'
import RedAlert from '../../ui-elements/RedAlert'
import Spinner from '../../ui-elements/Spinner'
import NewChecklistWrapper from '../wrapper/ChecklistSetupWrapper'
import SingleCheckWindow from './SingleCheckWindow'


type Props = {
    settingsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    settingsFormErrors: ObjectStringKeyAnyValue
    setSettingsFormFields: React.Dispatch<React.SetStateAction<any>>
}

function Frequency({
    settingsFormFields,
    thisChecklist,
    settingsFormErrors,
    setSettingsFormFields
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const handleChange = (value: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...settingsFormFields }
        newFieldValues['Schedule'] = { ...newFieldValues['Schedule'], 'Frequency': value }
        if (value === 'ADHOC') {
            delete newFieldValues['Schedule']['CheckWindows']
        }
        setSettingsFormFields(newFieldValues)
    }


    return <div className={`flex flex-col gap-2`}>
        <h3 className={`font-righteous text-brandblue text-lg`}>Checklist frequency:</h3>
        <select
            className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
            name={`ChecklistFrequency`}
            value={
                settingsFormFields &&
                settingsFormFields['Schedule'] &&
                settingsFormFields['Schedule']['Frequency'] ?
                settingsFormFields['Schedule']['Frequency'] : ''}
            onChange={(e) => handleChange(e.target.value)}
        >
            <option value={''}>Please select</option>
            <option value={`ADHOC`}>Ad-hoc</option>
            <option value={`WEEKLY`}>Scheduled</option>
        </select>
        {settingsFormErrors && settingsFormErrors['Schedule'] && settingsFormErrors['Schedule']['Frequency'] && <RedAlert>{settingsFormErrors['Schedule']['Frequency']}</RedAlert>}
    </div>
}

export default Frequency