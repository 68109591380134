import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { subscribe } from "../utils/pubsub"
import FormatQuestionAnswer from '../FormatQuestionAnswer'
import Button from '../formfields/Button'
import SubNavigation from '../shared-components/SubNavigation'
import { GlobalContext } from '../GlobalContext'
import Card from '../ui-elements/Card'
import GreenAlert from '../ui-elements/GreenAlert'
import RedAlert from '../ui-elements/RedAlert'
import { getDate } from '../utils/getDate'
import { getDateFromMonthYearDay } from '../utils/getDateFromMonthYearDay'
import { getDaysInMonth } from '../utils/getDaysInMonth'
import { getStartAndEndTimestampsForDay } from '../utils/getStartAndEndTimestampsForDay'
import { getStartAndEndTimestampsForMonth } from '../utils/getStartAndEndTimestampsForMonth'
import Spinner from '../ui-elements/Spinner'
import FormatChecklistTimeWindow from './FormatChecklistTimeWindow'

type Props = {
}

type ChecklistPeriodsProps = {
  thisCheckPeriod: ObjectStringKeyAnyValue
}





function ChecklistPeriods({
  thisCheckPeriod
}: ChecklistPeriodsProps) {

  const {
    tableData,
  } = useContext(GlobalContext)






  return <div className={`w-full`}>



    {thisCheckPeriod.IsComplete ?
      <GreenAlert icon={true} size={`small`} alignment={'left'}>
        <p className={`w-full`}>
          <FormatChecklistTimeWindow thisCheckPeriod={thisCheckPeriod} />
        </p>
      </GreenAlert> :
      // <RedAlert icon={true} size={`small`} alignment={'left'}>
      <div className={`rounded bg-gray-200 border border-gray-300 text-xs px-2 py-1 text-center`}>
        <p className={`w-full`}>
          <FormatChecklistTimeWindow thisCheckPeriod={thisCheckPeriod} />
        </p>
      </div>
      // </RedAlert>
    }
  </div>
}

function ChecklistResultsMonth({
}: Props) {

  const {
    tableData,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const shortMonths = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const navigate = useNavigate()
  const { checklistId, year, month } = useParams()
  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false)
  const [checklistPeriodsByDay, setChecklistPeriodsByDay] = useState<null | ObjectStringKeyAnyValue>(null)
  const [dateDropdownOptions, setDateDropdownOptions] = useState<null | Array<string>>(null)


  useEffect(() => {
    const newDateDropdownOptions: string[] = []
    const nowDate = new Date()
    const startMonth = (nowDate.getMonth() + 1)
    const startYear = nowDate.getFullYear()

    for (let i = 0; i < 12; i++) {
      let adjustedMonth = startMonth - i
      let adjustedYear = startYear
      if (adjustedMonth < 1) {
        adjustedYear--
        adjustedMonth = adjustedMonth + 12
      }
      const dateString = `${adjustedYear}-${adjustedMonth}`
      newDateDropdownOptions.push(dateString)
    }
    setDateDropdownOptions(newDateDropdownOptions)
  }, [year, month, checklistId])


  useEffect(() => {
    if (tableData && month && year) {

      if (!hasDataBeenFetched) {
        fetchData()
      }

      if (tableData.ChecklistAnswers) {
        const timeZone = tableData.Organisation.TimeZone || 'Europe/London'

        // loop through the days this month
        const newChecklistPeriodsByDay: ObjectStringKeyAnyValue = {}
        for (let day = 1; day <= 31; day++) {
          const dateString = `${year}-${`${month}`.padStart(2, '0')}-${`${day}`.padStart(2, '0')}`
          const { startTimestamp, endTimestamp } = getStartAndEndTimestampsForDay(dateString, timeZone)
          const checklistPeriodsForThisDay: ObjectStringKeyAnyValue = {}

          // find check periods for this day
          for (const key in tableData['ChecklistPeriods']) {
            const thisCheckPeriod = tableData['ChecklistPeriods'][key]
            if (
              thisCheckPeriod['ChecklistId'] === checklistId
              && parseInt(thisCheckPeriod['StartTimeStamp']) >= startTimestamp
              && parseInt(thisCheckPeriod['EndTimeStamp']) <= endTimestamp
            ) {
              checklistPeriodsForThisDay[key] = tableData['ChecklistPeriods'][key]
            }
          }

          // find answers for this check period
          if (tableData.ChecklistAnswers && Object.keys(checklistPeriodsForThisDay).length > 0) {
            newChecklistPeriodsByDay[day] = checklistPeriodsForThisDay
            for (const checklistPeriodId in checklistPeriodsForThisDay) {
              const answersForThisCheckPeriod = Object.values(tableData.ChecklistAnswers).filter((answer: any) => answer.ChecklistPeriodId === checklistPeriodId)
              newChecklistPeriodsByDay[day][checklistPeriodId]['Answers'] = answersForThisCheckPeriod
              const completedChecks = answersForThisCheckPeriod.filter((answer: any) => answer.Pending === false)
              const isCheckPeriodComplete = answersForThisCheckPeriod.length > 0 && completedChecks.length === answersForThisCheckPeriod.length ? true : false
              newChecklistPeriodsByDay[day][checklistPeriodId]['IsComplete'] = isCheckPeriodComplete
            }
          }
        }
        setChecklistPeriodsByDay(newChecklistPeriodsByDay)
      }
    }
  }, [tableData, month, year])





  const changeMonth = (monthAndYear: string) => {
    const dateParts = monthAndYear.split('-')
    const month = dateParts[1]
    const year = dateParts[0]
    navigate(`/checklists/${checklistId}/results/${year}/${month}`)
  }


  const fetchData = () => {
    const payload = JSON.stringify({
      "action": "checklists",
      "subAction": "fetchChecklistInfoByTimePeriod",
      "checklistId": checklistId,
      "month": month,
      "year": year
    })
    // console.log('🎣 fetching...')
    sendMessageToWebsocket(payload)
    setHasDataBeenFetched(true)
  }

  console.log(checklistPeriodsByDay)

  return <div className={`w-full flex flex-col items-center`}>


    <div className={`w-full bg-white flex flex-col items-center`}>
      <div className={`max-w-3xl w-full p-5 flex flex-row gap-4 items-start  justify-between`}>
        <div>
          <h3 className={`font-righteous text-3xl font-brandblue`}>
            {checklistId && tableData?.Checklists[checklistId].ChecklistName ? tableData.Checklists[checklistId].ChecklistName : "Untitled Checklist"}
          </h3>
          <div className={`mt-3 w-full flex flex-row gap-4 items-center`}>
            <p>Select month:</p>
            <select
              onChange={(e: any) => changeMonth(e.target.value)}
              className={`rounded px-2 py-1 bg-gray-200 border border-gray-400 text-gray-600 text-sm`}
              value={`${year}-${month}`}
            >
              {dateDropdownOptions?.map((dateOption, index) => {
                const dateParts = dateOption.split('-')
                const thisMonth = parseInt(dateParts[1]) - 1
                const thisYear = dateParts[0]
                return <option
                  key={index}
                  value={dateOption}>
                  {shortMonths[thisMonth]} - {thisYear}
                </option>
              })}
            </select>
          </div>
        </div>
        <Button
          text={'Checklist details'}
          internalLinkUrl={`/checklists/${checklistId}/`}
          variant={'primary'}
          size={'small'}
          fullwidth={false}
        />
      </div>
    </div>

    {!hasDataBeenFetched && <div className={`max-w-3xl w-full p-5 flex flex-col gap-4`}><Spinner>Loading...</Spinner></div>}

    {hasDataBeenFetched && <>

      {checklistId &&
        tableData &&
        tableData.Checklists &&
        tableData.Checklists[checklistId] ?
        <div className={`max-w-3xl w-full p-5 flex flex-col gap-4`}>

          {checklistPeriodsByDay && Object.keys(checklistPeriodsByDay).length === 0 && <RedAlert>No check periods for this month</RedAlert>}


          {checklistPeriodsByDay && Object.keys(checklistPeriodsByDay).length > 0 && <div className={`w-full flex md:flex-row flex-col flex-wrap gap-2`}>
            {Object.keys(checklistPeriodsByDay).map((day, index) => {
              const checklistPeriodsForThisDay: ObjectStringKeyAnyValue[] = checklistPeriodsByDay[day]
              return <div
                className={`md:w-44 w-full hover:opacity-80 cursor-pointer`}
                key={index}
                onClick={() => navigate(`/checklists/${checklistId}/results/${year}/${month}/${day}`)}
              >

                <Card key={index}>
                  <h4 className={`font-righteous text-xl font-brandblue`}>{getDateFromMonthYearDay(day, month, year)}</h4>
                  {Object.values(checklistPeriodsForThisDay).map((checkPeriod, index) => {
                    return <ChecklistPeriods
                      key={index}
                      thisCheckPeriod={checkPeriod}
                    />
                  })}
                </Card>
              </div>
            })}
          </div>}
        </div>
        :
        <div className={`max-w-3xl w-full p-5 flex flex-col gap-2`}>
          <RedAlert>Checklist ID: {checklistId} not found</RedAlert>
        </div >
      }
    </>}
  </div >

}
export default ChecklistResultsMonth