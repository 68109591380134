export const dateDiff = (date1, date2, format = 'days') => {
  const dateObj1 = new Date(parseInt(date1))
  const dateObj2 = new Date(parseInt(date2))
  const timeDifference = Math.abs(dateObj1 - dateObj2)
  const minutesDifference = timeDifference / (1000 * 60)
  const hoursDifference = timeDifference / (1000 * 60 * 60)
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)
  //console.log(`Date diff: ${daysDifference} days / ${hoursDifference} hours / ${minutesDifference} minutes`)
  if (format === 'hours') {
    return hoursDifference
  } else if (format === 'minutes') {
    return minutesDifference
  } else {
    return daysDifference
  }
}