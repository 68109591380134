import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import Spinner from '../ui-elements/Spinner'

type Props = {
  loginUrl: string
}

function LoginRedirect({
  loginUrl
}: Props) {
  return <div className={`pt-6 w-full flex flex-col items-center`}>
    <Spinner size="large">
  </Spinner>
    <p className={`mt-6 mb-2`}>Redirecting to login page</p>
    <p><a href={loginUrl} className={`underline`}>Click here</a> if you aren't redirected automatically after 5 seconds</p>
</div>


}

export default LoginRedirect