import React, { useContext, useState, useEffect } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'
import Button from '../../formfields/Button'
import SingleAnswerAction from './SingleAnswerAction'
import { useSVGOverlay } from 'react-leaflet/lib/SVGOverlay'


type Props = {
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
    setQuestionsFormErrors: React.Dispatch<React.SetStateAction<any>>
    addAnswerAction: AnyFunction
    cancelAnswerActions: AnyFunction
    showCancelAnswerActionsButton: boolean
}

function AnswerActions({
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields,
    setQuestionsFormErrors,
    addAnswerAction,
    cancelAnswerActions,
    showCancelAnswerActionsButton
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)






    return <div className={`flex flex-col gap-2`}>
        <div className={`flex flex-row items-center gap-2 justify-between mb-2`}>
            <h3 className={`font-righteous text-brandblue text-lg`}>Answer actions:</h3>

            <div className={`flex flex-row items-center gap-2 justify-between mb-2`}>
            <Button
                onClick={addAnswerAction}
                text={`Add`}
                variant={`primary`}
                fullwidth={false}
            />
            {showCancelAnswerActionsButton && <Button
                onClick={cancelAnswerActions}
                text={`Cancel`}
                variant={`gray`}
                fullwidth={false}
            />}
        </div>
        </div>

        {
            questionsFormFields &&
            questionsFormFields["AnswerActions"] &&
            Object.keys(questionsFormFields["AnswerActions"]).length > 0 &&
            <div className={`flex flex-row flex-wrap gap-2 mb-4`}>
                {Object.keys(questionsFormFields["AnswerActions"]).map((actionId: string, index: number) => {
                    return <SingleAnswerAction
                        key={index}
                        actionId={actionId}
                        questionsFormFields={questionsFormFields}
                        thisChecklist={thisChecklist}
                        questionsFormErrors={questionsFormErrors}
                        setQuestionsFormFields={setQuestionsFormFields}
                        setQuestionsFormErrors={setQuestionsFormErrors}
                    />
                })}
            </div>
        }

        {questionsFormErrors['AnswerActions'] && <RedAlert alignment='left'>{questionsFormErrors['AnswerActions']}</RedAlert>}
    </div>
}



export default AnswerActions