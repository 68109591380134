import React, { useContext } from 'react'
import { GlobalContext } from '../GlobalContext'

type Props = {
  formFields: { [key: string]: any }
  labelText: string
  handleChange: (key: string, value: any) => void
  fieldName: string
  optionValues?: Array<any>
  errorMessage?: string,
  size?: 'big' | 'small',
  center?: boolean
  initialSelectionText?: string
}

function Radio({
  formFields,
  labelText,
  handleChange,
  fieldName,
  optionValues = [{ label: 'Yes', value: true }, { label: 'No', value: false }],
  errorMessage,
  size,
  center
}: Props) {


  return <div className={`${center ? 'text-center' : 'text-left'} w-full flex flex-col gap-2`}>
    {labelText && <p className={`${center ? 'text-center' : 'text-left'}`}>{labelText}</p>}


      {optionValues && <div className={`flex flex-row gap-4`}>
        {optionValues.map((option, index) => {
          let isChecked = false
          if (formFields[fieldName]['value'] !== undefined && formFields[fieldName]['value'] !== null && `${formFields[fieldName]['value']}` === `${option.value}`){
            isChecked = true
          }

        return <label key={index} className={`flex flex-row gap-1`}>
          <input
          type={'radio'}
          key={index}
          name={fieldName}
          value={option.value}
          checked={isChecked}
          onChange={() => handleChange(fieldName, option.value)}
        />
          {option.label}
          </label>
      })}
      </div>}

      {formFields[fieldName] && formFields[fieldName]['isValid'] === false && <p className={`${center ? 'text-center' : 'text-left'} text-xs text-red-700`}>
        {errorMessage ? errorMessage : 'Please select a value'}
      </p>}
  </div>
}
export default Radio