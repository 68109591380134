import React, { useEffect, useState, useContext, Children } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import { GlobalContext } from '../../GlobalContext'
import { updateObjectKeyAndValue } from '../../utils/updateObjectKeyAndValue'
import RedAlert from '../../ui-elements/RedAlert'
import Spinner from '../../ui-elements/Spinner'
import Modal from '../../ui-elements/Modal'
import Button from '../../formfields/Button'
import { validateForm } from '../../utils/validateForm'

type Props = {
  children?: any
  formFields?: any
  setFormFields?: React.Dispatch<React.SetStateAction<any>>
  title: string
  subtitle?: string
  prevButtonUrl?: string | null
  nextButtonUrl?: string | null
  nextButtonAction?: any
  prevButtonText?: string
  nextButtonText?: string
  savingProgress: boolean
  setSavingProgress: React.Dispatch<React.SetStateAction<any>>
}

function ChecklistWrapper({
  children,
  formFields,
  setFormFields,
  title,
  subtitle,
  prevButtonUrl,
  nextButtonUrl,
  prevButtonText,
  nextButtonText,
  nextButtonAction,
  savingProgress,
  setSavingProgress,
}: Props) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields,
    checklistQuestionFields,
    setChecklistQuestionFields
  } = useContext(GlobalContext)



  const { checklistId, questionId } = useParams()


  useEffect(() => {
    // saveProgressToLocalStorage()
    checkIfChecklistIdHasChanged()
  }, [checklistSetupFields])


  useEffect(() => {
    populateFromTableData()
  }, [tableData, checklistSetupFields, questionId])

  const checkIfChecklistIdHasChanged = () => {
    // if params is not new && checklist fields has an id but it doesnt match the params
    // if params is new && checklist fields has an id
    if (
      (checklistId && checklistId === 'new' && checklistSetupFields && checklistSetupFields['Id']) ||
      (checklistId && checklistId !== 'new' && checklistSetupFields && checklistSetupFields['Id'] !== checklistId)
    ) {
      //console.log('❤️‍🔥 id has changed')
      setChecklistSetupFields(null)
    }
  }

  const populateFromTableData = () => {
    if (
      tableData &&
      checklistId &&
      checklistId !== 'new'
    ) {
      if (
        !checklistSetupFields &&
        tableData['Checklists'] &&
        tableData.Checklists[checklistId]
      ) {
        setChecklistSetupFields(tableData.Checklists[checklistId])
      }
      if (
        questionId &&
        (!checklistQuestionFields || checklistQuestionFields['Id'] !== questionId) &&
        tableData['ChecklistQuestions'] &&
        tableData['ChecklistQuestions'][questionId]
      ) {
        setChecklistQuestionFields(tableData['ChecklistQuestions'][questionId])
      }
    }
  }

  const saveProgressToLocalStorage = () => {
    // if this is a new checklist, save progress to localstorage
    if (checklistId === 'new') {
      const fieldsFromLocalStorage = localStorage.getItem('checklistSetupFields')
      if (checklistSetupFields) {
        localStorage.setItem('checklistSetupFields', JSON.stringify(checklistSetupFields))
      } else if (fieldsFromLocalStorage && setFormFields && !JSON.parse(fieldsFromLocalStorage)['Id']) {
        console.log('!!!')
        setChecklistSetupFields(JSON.parse(fieldsFromLocalStorage))
      }
    }
  }



  return <div className={`flex-1 h-full flex flex-col justify-between gap-2 bg-gray-200`}>
    <div className={`flex flex-col gap-2 items-center`}>
      <div className={`w-full bg-white flex flex-row gap-4 items-center justify-center border-b-2 border-gray-300`}>
        <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}>
          <h3 className={`font-righteous text-3xl font-brandblue`}>{title}</h3>
          {subtitle && <p className={`text-md`}>{subtitle}</p>}
          {/* <p className={`text-xs`}>
            Checklist State: {checklistSetupFields ?
              JSON.stringify(checklistSetupFields).replaceAll(',', ', ').replaceAll('%', '% ')
              : 'null'}</p> */}

          {/* <p className={`text-xs`}>
            Questions State: {checklistQuestionFields ?
              JSON.stringify(checklistQuestionFields).replaceAll(',', ', ')
              : 'null'}</p> */}

          {/* <p className={`mt-2 text-xs`}>
            Local formfields: {formFields && JSON.stringify(formFields).replaceAll(',', ', ')}</p> */}

        </div>
      </div>
      <div className={`max-w-3xl w-full p-5`}>

        {savingProgress === true && <Modal
          showCloseButton={true}
          setShowModal={setSavingProgress}>
          <Spinner><p>Saving...</p></Spinner>
        </Modal>}

        {!tableData && <Spinner><p>Loading...</p></Spinner>}

        {tableData && tableData.Checklists && checklistId && checklistId !== 'new' && !tableData.Checklists[checklistId] && <RedAlert>Sorry - no checklist with ID "{checklistId}" has been found</RedAlert>}

        {tableData && tableData.Checklists &&
          (!checklistId ||
            (checklistId && (checklistId === 'new' || tableData.Checklists[checklistId]))
          ) &&
          children
        }

      </div>
    </div>

    {(prevButtonUrl || nextButtonUrl || nextButtonAction) && <div className={`bg-white flex flex-row gap-4 items-center justify-center border-t-2 border-gray-300`}>
      <div className={`max-w-3xl w-full p-5 flex flex-row gap-5`}>
        {prevButtonUrl && <Button
          internalLinkUrl={prevButtonUrl || '/'}
          text={prevButtonText || 'Back'}
          disabled={prevButtonUrl ? false : true}
          variant={'primary'}
          size={'big'}
        />}
        {nextButtonAction && <Button
          onClick={(e: any) => {
            e.preventDefault()
            nextButtonAction.function(nextButtonAction.params)
          }}
          text={`${nextButtonText || 'Next'}`}
          variant={'primary'}
          // disabled={isNextStepValid ? false : true}
          // variant={isNextStepValid ? 'primary' : 'gray'}
          size={'big'}
        />}
        {nextButtonUrl && !nextButtonAction && <Button
          internalLinkUrl={nextButtonUrl || '/'}
          text={`${nextButtonText || 'Next'}`}
          variant={'primary'}
          // disabled={isNextStepValid ? false : true}
          // variant={isNextStepValid ? 'primary' : 'gray'}
          size={'big'}
        />}

      </div>
    </div>}
  </div>
}
export default ChecklistWrapper