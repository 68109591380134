import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'
import { useParams } from 'react-router-dom'


type Props = {
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
}


type IndividualFridgeProps = {
    selectedFridgeId: string | null
    setSelectedFridgeId: React.Dispatch<React.SetStateAction<any>>
    thisFridge: ObjectStringKeyAnyValue
    disabled: boolean
}

function IndividualFridge({
    selectedFridgeId,
    setSelectedFridgeId,
    thisFridge,
    disabled
}: IndividualFridgeProps) {


    return <div
        className={`
      ${!disabled && selectedFridgeId === thisFridge.Id && ' bg-brandblue border-brandblue text-white '}
      ${!disabled && selectedFridgeId !== thisFridge.Id && ' bg-white border-gray-400'}
      ${disabled ? ' border-gray-400 bg-gray-300 text-gray-700 ' : ' hover:opacity-80 cursor-pointer '}
      rounded py-2 px-4 border`}
        onClick={() => { !disabled && setSelectedFridgeId(thisFridge.Id) }}
    >
        <p className={`font-bold`}>{thisFridge.AssetName}</p>
        <p className={`text-xs`}>ID: #{thisFridge.Id}</p>
    </div>

}


function SelectFridge({
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields
}: Props) {

    const {
        tableData,
        fridges,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)

    const { checklistId, questionId } = useParams()
    const [alreadySelectedFridgeIds, setAlreadySelectedFridgeIds] = useState<Array<string>>([])

    useEffect(() => {
        // check which of the fridges have already been assigned to questions for this checklist
        if (tableData && tableData['ChecklistQuestions']) {
            const fridgeIdArray: string[] = []
            for (const thisQuestionId in tableData['ChecklistQuestions']) {
                const thisQuestion = tableData['ChecklistQuestions'][thisQuestionId]
                if (
                    thisQuestionId !== questionId &&
                    thisQuestion.ChecklistId === checklistId &&
                    thisQuestion.FridgeId
                ) {
                    fridgeIdArray.push(thisQuestion.FridgeId)
                }
            }
            setAlreadySelectedFridgeIds(fridgeIdArray)
        }
    }, [tableData])

    const handleChange = (value: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        newFieldValues['FridgeId'] = value
        setQuestionsFormFields(newFieldValues)
    }

    return <div className={`flex flex-col gap-2`}>
        <h3 className={`font-righteous text-brandblue text-lg`}>Select Fridge:</h3>

        {Object.keys(fridges).length === alreadySelectedFridgeIds.length ?
            <RedAlert alignment='left'>All fridges have already have temperature checks as part of this checklist</RedAlert> :
            <> {questionsFormErrors['FridgeId'] && <RedAlert alignment='left'>{questionsFormErrors['FridgeId']}</RedAlert>}</>
        }

        {fridges && Object.keys(fridges).length > 0 && <div className={`flex flex-row flex-wrap gap-2 mb-4`}>
            {Object.keys(fridges).map((fridgeId, index) => {
                const thisFridge = fridges[fridgeId]
                if (!alreadySelectedFridgeIds.includes(fridgeId)) {
                    return <IndividualFridge
                        key={index}
                        selectedFridgeId={questionsFormFields['FridgeId']}
                        setSelectedFridgeId={handleChange}
                        thisFridge={thisFridge}
                        disabled={false}
                    />
                }
            })}

            {Object.keys(fridges).map((fridgeId, index) => {
                const thisFridge = fridges[fridgeId]
                if (alreadySelectedFridgeIds.includes(fridgeId)) {
                    return <IndividualFridge
                        key={index}
                        selectedFridgeId={questionsFormFields['FridgeId']}
                        setSelectedFridgeId={handleChange}
                        thisFridge={thisFridge}
                        disabled={true}
                    />
                }
            })}

        </div>}

    </div>
}



export default SelectFridge