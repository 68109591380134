export const getDateFromMonthYearDay = (day, month, year) => {
  // Create a new Date object (month is zero-indexed, so subtract 1 from the month)
  const date = new Date(year, month - 1, day);

  // Get the day of the week, month, and year
  const optionsWeekday = { weekday: 'short' };
  const optionsMonth = { month: 'long' };
  const weekday = date.toLocaleDateString('en-US', optionsWeekday);
  const monthName = date.toLocaleDateString('en-US', optionsMonth);

  // Construct the final format "Tue 17 - June, 2024"
  // const friendlyDate = `${weekday} ${day} ${monthName}, ${year}`;
  const friendlyDate = `${weekday} ${addOrdinalSuffix(day)} ${monthName}`;

  return friendlyDate;
}

function addOrdinalSuffix(day) {
  if (day > 3 && day < 21) return day + 'th'; // for 4th to 20th
  switch (day % 10) {
    case 1: return day + 'st';
    case 2: return day + 'nd';
    case 3: return day + 'rd';
    default: return day + 'th';
  }
}