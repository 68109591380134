

export const checkifAnswerIsDuplicated = (answerActionId, thisAnswerAction, allAnswerActions) => {
    let isAnswerDuplicated = false
    for (const answerActionIdToCheck in allAnswerActions) {
        const answerActionToCheck = allAnswerActions[answerActionIdToCheck]
        if (
            // ids are not the same
            answerActionIdToCheck !== answerActionId &&
            // both actions to take are not blank and are the same
            thisAnswerAction['ActionForAnswer'] &&
            answerActionToCheck['ActionForAnswer'] &&
            Object.keys(thisAnswerAction['ActionForAnswer']).length > 0 &&
            Object.keys(answerActionToCheck['ActionForAnswer']).length > 0 &&
            JSON.stringify(thisAnswerAction['ActionForAnswer']) === JSON.stringify(answerActionToCheck['ActionForAnswer'])

        ) {
            isAnswerDuplicated = true
        }
    }
    return isAnswerDuplicated
}



export const validateChecklistQuestionsForm = (questionsFormFields, setQuestionsFormErrors) => {
    let isFormValid = true
    //console.log('validating')
    const newQuestionsFormErrors = {}

    // check if there is a valid checklist text
    if (
        !questionsFormFields['QuestionFormat']
    ) {
        newQuestionsFormErrors['QuestionFormat'] = 'Please select a question format'
        isFormValid = false
    }

    // check if there is a valid checklist text (not required if the question format is a fridge temperature)
    if (
        !questionsFormFields['QuestionText'] &&
        questionsFormFields['QuestionFormat'] !== 'FridgeTemperature'
    ) {
        newQuestionsFormErrors['QuestionText'] = 'Please enter some text'
        isFormValid = false
    }

    // if format is multi choice, ensure there are at least two options
    if (
        (!questionsFormFields['QuestionOptions'] || questionsFormFields['QuestionOptions'].length < 2) &&
        (questionsFormFields['QuestionFormat'] === 'SelectOne' || questionsFormFields['QuestionFormat'] === 'SelectMultiple')
    ) {
        newQuestionsFormErrors['QuestionOptions'] = 'Please add at least 2 options'
        isFormValid = false
    }

    // if it's a temperature check, ensure there is a fridge id
    if (
        !questionsFormFields['FridgeId'] &&
        questionsFormFields['QuestionFormat'] === 'FridgeTemperature'
    ) {
        newQuestionsFormErrors['FridgeId'] = 'Please select a fridge'
        isFormValid = false
    }


    // if there are answer actions
    if (
        questionsFormFields['AnswerActions'] &&
        Object.keys(questionsFormFields['AnswerActions']).length > 0
    ) {



        for (const answerActionId in questionsFormFields['AnswerActions']) {
            const thisAnswerAction = questionsFormFields['AnswerActions'][answerActionId]
            const errorFieldName = `AnswerAction-${answerActionId}`


            // check it doesn't match any other actions
            const isAnswerDuplicated = checkifAnswerIsDuplicated(answerActionId, thisAnswerAction, questionsFormFields['AnswerActions'])
            if (isAnswerDuplicated){
                newQuestionsFormErrors[errorFieldName] = `
                ${newQuestionsFormErrors[errorFieldName] || ''}
                There is more than one action for this answer. `
                isFormValid = false    
            }


            // if it's a fridge temp, check that there is a min and max
            if (
                questionsFormFields['QuestionFormat'] === 'FridgeTemperature' &&
                (
                    !thisAnswerAction['ActionForAnswer'] ||
                    !thisAnswerAction['ActionForAnswer']['min'] ||
                    !thisAnswerAction['ActionForAnswer']['max'])
            ) {
                newQuestionsFormErrors[errorFieldName] = `
                ${newQuestionsFormErrors[errorFieldName] || ''}
                Please include a minimum and maximum temperature. `
                isFormValid = false
            }

            // if it's a fridge temp, check that maximum temp exceeds minimum
            if (
                questionsFormFields['QuestionFormat'] === 'FridgeTemperature' &&
                thisAnswerAction['ActionForAnswer'] &&
                thisAnswerAction['ActionForAnswer']['min'] &&
                thisAnswerAction['ActionForAnswer']['max'] &&
                (parseInt(thisAnswerAction['ActionForAnswer']['min']) >= parseInt(thisAnswerAction['ActionForAnswer']['max']))
            ) {
                newQuestionsFormErrors[errorFieldName] = `
                ${newQuestionsFormErrors[errorFieldName] || ''}
                Maximum temperature must be greater than minimum temperature.`
                isFormValid = false
            }

            // if it's not a fridge temp, check that an answer is selected
            if (
                questionsFormFields['QuestionFormat'] !== 'FridgeTemperature' &&
                (
                    !thisAnswerAction['ActionForAnswer'] ||
                    !thisAnswerAction['ActionForAnswer']['equals'])
            ) {
                newQuestionsFormErrors[errorFieldName] = `
                ${newQuestionsFormErrors[errorFieldName] || ''}
                Please select an answer.`
                isFormValid = false
            }

            // ensure an action is selected
            if (
                (!thisAnswerAction['ActionToTake'])
            ) {
                newQuestionsFormErrors[errorFieldName] = `
                ${newQuestionsFormErrors[errorFieldName] || ''} 
                Please select a follow up action. `
                isFormValid = false
            }
        }

    }

    // setQuestionsFormFields(questionsFormFields)
    setQuestionsFormErrors(newQuestionsFormErrors)
    //return false
    return isFormValid
}
