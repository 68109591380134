import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import { subscribe } from "../utils/pubsub"
import DeleteButton from '../formfields/DeleteButton'
import { validateForm } from '../utils/validateForm'
import Button from '../formfields/Button'
import Radio from '../formfields/Radio'
import Text from '../formfields/Text'
import Card from '../ui-elements/Card'
import SubNavigation from '../shared-components/SubNavigation'
import FridgeCard from './FridgeCard'

type ObjectAny = {
  [key: string]: any
}


type Props = {

}


function ListFridges({

}: Props) {



  const initialFormData = {
    "AssetName": { "value": '', "required": true, "type": "text" },
    "HasDigitalReadout": { "value": '', "required": true, "type": "radio" },
    "DigitsBeforeDecimal": { "value": '', "required": false, "type": "number" },
    "DigitsAfterDecimal": { "value": '', "required": false, "type": "number" },
    // "ProfileId": { "value": '', "required": false, "type": "dropdown" },
    "IsActive": { "value": '', "required": false, "type": "radio" },
    // "GroupId": { "value": '', "required": false, "type": "dropdown" }
  }

  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)



  return <div className={`flex flex-col items-center`}>

 

    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>


      <div className={`w-full flex flex-row justify-between items-center gap-4`}>

        <h2 className={`text-3xl  font-bold font-righteous text-brandblue`}
        >Fridges and Freezers</h2>
        <Button
          internalLinkUrl={`/fridges/add-edit/`}
          text={'Add new'}
          variant={'primary'}
          fullwidth={false}
        />
      </div>




      {tableData === null &&
        <Spinner><p>Loading...</p></Spinner>
      }

      {tableData && fridges && Object.values(fridges).length === 0 &&
        <p>Sorry, no fridges have been saved yet</p>
      }





      <div className={`w-full flex flex-row gap-2 flex-wrap`}>
        {fridges && Object.values(fridges).map((fridge: any, index: number) => <FridgeCard
          key={index}
          fridge={fridge}
        />)}
      </div>



    </div>
  </div>
}
export default ListFridges