
import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate } from "react-router-dom"
import Button from '../formfields/Button'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'


type Props = {

}


function CardJustAdded({
}: Props) {

    const navigate = useNavigate()

    useEffect(() => {
        const currentTime = Date.now();
        localStorage.setItem('lastPaymentRequestTimestamp', currentTime.toString());
        navigate('/dashboard')
    }, []);


    return <div className='w-full border border border-gray-200 p-4 bg-white rounded shadow flex flex-col gap-2 items-start'>

       <Spinner>Card added</Spinner>
    </div>
}

export default CardJustAdded