import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../../GlobalContext'
import Button from '../../formfields/Button'
import Card from '../../ui-elements/Card'
import RedAlert from '../../ui-elements/RedAlert'
import Spinner from '../../ui-elements/Spinner'
import NewChecklistWrapper from '../wrapper/ChecklistSetupWrapper'
import SingleCheckWindow from './SingleCheckWindow'
import ListCheckWindows from './ListCheckWindows'
import ChecklistName from './ChecklistName'
import Frequency from './Frequency'


type Props = {
    settingsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    settingsFormErrors: ObjectStringKeyAnyValue
    setSettingsFormFields: React.Dispatch<React.SetStateAction<any>>
    setSettingsFormErrors: React.Dispatch<React.SetStateAction<any>>
}




function ChecklistSettings({
    settingsFormFields,
    thisChecklist,
    settingsFormErrors,
    setSettingsFormFields,
    setSettingsFormErrors
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const { checklistId } = useParams()
    const [savingProgress, setSavingProgress] = useState(false)


    useEffect(() => {

    }, [tableData, checklistId])




    return <div className={`w-full flex flex-col gap-4`}>

        <ChecklistName
            thisChecklist={thisChecklist}
            settingsFormFields={settingsFormFields}
            settingsFormErrors={settingsFormErrors}
            setSettingsFormFields={setSettingsFormFields}
        />

        <Frequency
            thisChecklist={thisChecklist}
            settingsFormFields={settingsFormFields}
            settingsFormErrors={settingsFormErrors}
            setSettingsFormFields={setSettingsFormFields}
        />


        {settingsFormFields && 
        settingsFormFields['Schedule'] && 
        settingsFormFields['Schedule']['Frequency'] && 
        settingsFormFields['Schedule']['Frequency'] === 'WEEKLY' &&
            <ListCheckWindows
                thisChecklist={thisChecklist}
                settingsFormFields={settingsFormFields}
                settingsFormErrors={settingsFormErrors}
                setSettingsFormFields={setSettingsFormFields}
                setSettingsFormErrors={setSettingsFormErrors}
            />
        }
    </div>
}
export default ChecklistSettings