import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import SummaryItem from './SummaryItem'
import SummaryCheckWindow from './SummaryCheckWindow'
import EnableOrDisableChecklist from './EnableOrDisableChecklist'
import { tableData } from '../tests/testTableData'

type Props = {
  checklistData: ObjectStringKeyAnyValue
  showEditButton?: boolean
}

function SettingsDetails({
  checklistData,
  showEditButton = true
}: Props) {

  function textForScheduleFrequency(databaseValue: string) {
    if(databaseValue == 'ADHOC') {
      return 'Ad-hoc'
    }
    if(databaseValue == 'WEEKLY') {
      return 'Scheduled'
    }

  }

  const { checklistId } = useParams()
  const [savingProgress, setSavingProgress] = useState(false)

  return <div>

    <Card>
      <div className={`w-full flex flex-row gap-4 items-center justify-between`}>
        <h4 className={`font-righteous text-xl font-brandblue`}>Settings</h4>

        <Button
          internalLinkUrl={`/checklists/${checklistId}/settings`}
          text={'Edit'}
          fullwidth={false}
        />
      </div>

      {showEditButton && <EnableOrDisableChecklist
        thisChecklist={checklistData}
      // savingProgress={savingProgress}
      // setSavingProgress={setSavingProgress}
      />}
      <SummaryItem
        title={'Checklist Name'}
        link={`settings/checklist-name`}
        value={checklistData['ChecklistName']}
        showEditButton={false} />

      <SummaryItem
        title={'Schedule Frequency'}
        link={`settings/schedule-frequency`}
        value={checklistData['Schedule'] ? textForScheduleFrequency(checklistData['Schedule']['Frequency']) : null}
        showEditButton={false} />

      {checklistData && checklistData['Schedule'] && checklistData['Schedule']['Frequency'] !== 'ADHOC' && <SummaryCheckWindow
        title={'Check Windows'}
        link={`settings/schedule-weekly`}
        value={checklistData['Schedule'] ? checklistData['Schedule']['CheckWindows'] : null}
        showEditButton={false} />}



    </Card>





  </div>
}
export default SettingsDetails