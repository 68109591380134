import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'

type Props = {

}
type IndividualPresetProps = {
    PresetId: string
    PresetName: string
    PresetDescription: string
    addPreset: AnyFunction
}

function IndividualPreset({
    PresetId,
    PresetName,
    PresetDescription,
    addPreset
}: IndividualPresetProps) {

    return <Card fullwidth={false}>
        <div className={`w-48 h-full flex flex-col justify-between gap-4`}>
            <div className={`flex flex-col gap-1`}>
                <h2 className={`font-righteous text-brandblue text-lg`}>{PresetName}</h2>
                <p>{PresetDescription}</p>
            </div>
            <Button
                text={`Add`}
                onClick={() => addPreset(PresetId)}
                variant={`primary`}
                // size={`big`}
                fullwidth={false}
            />
        </div>
    </Card>
}


function PresetChecklists({

}: Props) {
    const {
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket,
        checklistSetupFields,
        setChecklistSetupFields
    } = useContext(GlobalContext)

    const { checklistId } = useParams()
    const navigate = useNavigate()
    const [savingProgress, setSavingProgress] = useState(false)

    const addPreset = (PresetId: string) => {
        setSavingProgress(true)
        const newChecklistId = `${PresetId}-${Math.round(Math.random() * 1000)}-${Date.now()}`
        const payload = JSON.stringify({
            action: "checklists",
            subAction: "addPresetChecklist",
            presetChecklist: PresetId,
            newChecklistId: newChecklistId
        })
        sendMessageToWebsocket(payload)
        const unsubscribe = subscribe('tableUpdateItemAdded', data => {
            console.log(data)
            if (data && data.newRecord && data.newRecord.Id && data.newRecord.Id === newChecklistId) {
                setSavingProgress(false)
                navigate('/dashboard')
                unsubscribe()
            }
        })
    }

    return <div className={`w-full flex flex-col items-center justify-center`}>


        {savingProgress && <Modal
            showCloseButton={true}
            setShowModal={setSavingProgress}
        ><Spinner><p>Adding...</p></Spinner>
        </Modal>}


        <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>
            <h2 className={`text-3xl  font-bold font-righteous text-brandblue`}>
                Add a checklist template</h2>

            <div className={`flex flex-row flex-wrap gap-2`}>
                <IndividualPreset
                    PresetId={`sfbb-opening`}
                    PresetName={`Opening checks`}
                    PresetDescription={`Daily opening checks`}
                    addPreset={addPreset}
                />
                <IndividualPreset
                    PresetId={`sfbb-closing`}
                    PresetName={`Closing checks`}
                    PresetDescription={`Daily closing checks`}
                    addPreset={addPreset}
                />
                <IndividualPreset
                    PresetId={`hospitality-delivery`}
                    PresetName={`Delivery checks`}
                    PresetDescription={`Ad hoc checklist for receiving deliveries`}
                    addPreset={addPreset}
                />
            </div>
        </div>
    </div >
}
export default PresetChecklists