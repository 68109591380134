import React, { useEffect, useState } from 'react'
import { json, Link, useParams, useNavigate } from 'react-router-dom'
import { subscribe } from "../utils/pubsub"
import { getDateFromTimestamp } from "../utils/getDateFromTimestamp"
import formSections from '../utils/accident-form-fields.json'
import riddorForm from '../utils/riddor-form-fields.json'
import Spinner from '../ui-elements/Spinner'


type ObjectAny = {
  [key: string]: any
}

type Props = {
  tableData: ObjectAny | null,
  setTableData: React.Dispatch<React.SetStateAction<ObjectAny | null>>
  socket: WebSocket | null
  sendMessageToWebsocket: (payload: string) => void
  idToken: string | null
  accessToken: string | null
  orgSettings: any
  userData: ObjectAny | null
}

type FieldProps = {
  field: ObjectAny
  fullFieldName: string
  riddorFormInputs: ObjectAny
  riddorFormErrors: ObjectAny
  options?: Array<any>
  handleChange: (
    fieldName: string,
    fieldValue: string | boolean
  ) => void
}

type NotesProps = {
  userData: ObjectAny | null
  tableData: ObjectAny | null
  Id: string | undefined
  noteKey: string
  deleteNote: (noteKey: string) => void
}

const DateField = ({ field, fullFieldName, riddorFormInputs, riddorFormErrors, handleChange, options }: FieldProps) => {
  const isValid: boolean = riddorFormInputs[fullFieldName] && riddorFormInputs[fullFieldName].valid ? riddorFormInputs[fullFieldName].valid : true

  const now = new Date()
  const maxYear = now.getFullYear()
  const maxMonth = now.getMonth() + 1
  const maxDay = now.getDate()


  return <div>
    <div className={`mt-3 mb-1`}>
      <h4 className={`font-bold`}>{field.label}
        {field.required !== false && (
          <span className="font-bold text-red-500 text-xl">*</span>


        )}  </h4>


      {field.note && <p>{field.note}</p>}
    </div>
    <input
      type='date'
      className={`w-full bg-green-100 outline-none border-b-2  py-1 px-2 border-firstaidgreen ${riddorFormErrors[fullFieldName] && 'border-red-500'}`}
      value={riddorFormInputs[fullFieldName] ? riddorFormInputs[fullFieldName] : ''}
      min={`${maxYear - 1}-${maxMonth < 10 ? `0${maxMonth}` : maxMonth}-${maxDay < 10 ? `0${maxDay}` : maxDay}`}
      max={`${maxYear}-${maxMonth < 10 ? `0${maxMonth}` : maxMonth}-${maxDay < 10 ? `0${maxDay}` : maxDay}`}
      onChange={(e) =>
        handleChange(
          fullFieldName,
          e.target.value
        )}
    />

    {riddorFormErrors[fullFieldName] && <p className={`text-red-500 text-sm`}>Please enter a valid date</p>}
  </div>
}

const TextField = ({ field, fullFieldName, riddorFormInputs, riddorFormErrors, handleChange }: FieldProps) => {
  const isValid: boolean = riddorFormInputs[fullFieldName] && riddorFormInputs[fullFieldName].valid ? riddorFormInputs[fullFieldName].valid : true
  return <div>
    <div className={`mt-3 mb-1`}>
      <h4 className={`font-bold`}>{field.label}
        {field.required !== false && (
          <span className="font-bold text-red-500 text-xl">*</span>
        )}</h4>
      {field.note && <p>{field.note}</p>}
    </div>
    <input
      className={`w-full bg-green-100 outline-none border-b-2  py-1 px-2 border-firstaidgreen ${riddorFormErrors[fullFieldName] && 'border-red-500'}`}
      type="text"
      value={riddorFormInputs[fullFieldName] ? riddorFormInputs[fullFieldName] : ''}
      onChange={(e) =>
        handleChange(
          fullFieldName,
          e.target.value
        )
      }
    />
    {riddorFormErrors[fullFieldName] && <p className={`text-red-500 text-sm`}>Please enter some text</p>}
  </div>
}
const EmailField = ({ field, fullFieldName, riddorFormInputs, riddorFormErrors, handleChange }: FieldProps) => {
  const isValid: boolean = riddorFormInputs[fullFieldName] && riddorFormInputs[fullFieldName].valid ? riddorFormInputs[fullFieldName].valid : true
  return <div>

    <div className={`mt-3 mb-1`}>
      <h4 className={`font-bold`}>{field.label}
        {field.required !== false && (
          <span className="font-bold text-red-500 text-xl">*</span>
        )}</h4>
      {field.note && <p>{field.note}</p>}
    </div>
    <input
      className={`w-full bg-green-100 outline-none border-b-2  py-1 px-2 border-firstaidgreen ${riddorFormErrors[fullFieldName] && 'border-red-500'}`}
      type="email"
      value={riddorFormInputs[fullFieldName] ? riddorFormInputs[fullFieldName] : ''}
      onChange={(e) =>
        handleChange(
          fullFieldName,
          e.target.value
        )
      }
    />
    {riddorFormErrors[fullFieldName] && <p className={`text-red-500 text-sm`}>Please enter a valid email address</p>}
  </div>
}

const Note = ({
  userData,
  tableData,
  Id,
  noteKey,
  deleteNote
}: NotesProps) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const note = JSON.parse(tableData!['Incidents'][`${Id}`]['Notes'])[noteKey]
  const isActive = note.active === false ? false : true
  if (!isActive) {
    return null
  }
  return <div className={`p-4 bg-yellow-100 border border-yellow-300 rounded hover:opacity-90-xs shadow w-full`}>
    <p>{note.content}</p>
    <p className={`text-xs text-gray-600`}>Added {getDateFromTimestamp(noteKey)} by {note.name}</p>

    {note['cognito:username'] === userData!['cognito:username'] && <>
      {showConfirmDelete ? <div><p className={`mt-3 text-xs text-gray-600`}>Are you sure?</p> <button
        className={`mt-3 bg-yellow-400 hover:bg-yellow-500 text-xs font-bold py-1 px-2 rounded hover:opacity-90`}
        onClick={() => {
          setShowConfirmDelete(false)
          deleteNote(noteKey)
        }}
      >Confirm</button></div> :
        <button
          className={`mt-3 bg-yellow-400 hover:bg-yellow-500 text-xs font-bold py-1 px-2 rounded hover:opacity-90`}
          onClick={() => setShowConfirmDelete(true)}
        >Delete</button>
      }
    </>}




  </div>

}



function SingleIncident({
  tableData,
  setTableData,
  socket,
  sendMessageToWebsocket,
  idToken,
  accessToken,
  orgSettings,
  userData,
}: Props) {
  const navigate = useNavigate();


  let { Id } = useParams()
  const [mode, setMode] = useState('loading')
  const [reportData, setReportData] = useState<ObjectAny>({})
  const [showAddNote, setShowAddNote] = useState(false)
  const [noteContent, setNoteContent] = useState<string | null>(null)
  const [riddorFormInputs, setRiddorFormInputs] = useState<ObjectAny>({})
  const [riddorFormErrors, setRiddorFormErrors] = useState<ObjectAny>({})
  const [sortedNoteKeys, setSortedNoteKeys] = useState<Array<string>>([])





  useEffect(() => {
    if (Id && tableData && tableData['Incidents'] && tableData['Incidents'][Id]) {
      setMode('showdetails')
      const newReportData = JSON.parse(tableData['Incidents'][Id].reportData)

      if (tableData['Incidents'][Id]['IsRiddorReportable']) {
        newReportData['IsRiddorReportable'] = tableData['Incidents'][Id]['IsRiddorReportable']
      }
      if (tableData['Incidents'][Id]['RiddorFormDetails']) {
        newReportData['RiddorFormDetails'] = tableData['Incidents'][Id]['RiddorFormDetails']
      }


      if (tableData['Incidents'][Id]['Notes']) {
        sortNoteKeys(tableData['Incidents'][Id]['Notes'])
      }

      setReportData(newReportData)
    } else if (Id && tableData && tableData['Incidents'] && !tableData['Incidents'][Id]) {
      setMode('notfound')
    }

  }, [tableData])


  const sortNoteKeys = (allNotesData: string) => {
    const noteTimestamps = Object.keys(JSON.parse(allNotesData))
    noteTimestamps.sort(function (a, b) { return parseInt(b) - parseInt(a) })
    setSortedNoteKeys(noteTimestamps)
  }



  const handleChange = (
    fieldName: string,
    fieldValue: string | boolean
  ) => {

    // update user inputs
    if (fieldValue === '') {
      const newriddorFormInputs = { ...riddorFormInputs }
      delete newriddorFormInputs[fieldName]
      setRiddorFormInputs(newriddorFormInputs)
    } else {
      const newriddorFormInputs = { ...riddorFormInputs }
      newriddorFormInputs[fieldName] = fieldValue
      setRiddorFormInputs(newriddorFormInputs)
    }

    // clear any errors for this field
    const newriddorFormErrors = { ...riddorFormErrors }
    delete newriddorFormErrors[fieldName]
    setRiddorFormErrors(newriddorFormErrors)
  }


  const validate = (
    event: any
  ) => {
    event.preventDefault()


    const newriddorFormErrors: ObjectAny = {}
    const inputFields: Array<ObjectAny> = riddorForm.fields
    for (const field of inputFields) {
      const fieldName = `riddor-${field.name}`

      if (field.required === true) {
        if (!riddorFormInputs[fieldName]) {
          // check if all required fields have been completed
          //console.log(`${fieldName} is incomplete`)
          newriddorFormErrors[fieldName] = 'incomplete'
        } else if (riddorFormInputs[fieldName]) {
          // if field has been completed, check if it is valid
          let isValid = true
          let fieldValue = riddorFormInputs[fieldName]
          switch (field.type) {
            case 'text':
              isValid = fieldValue.length > 0
              break
            case 'dropdown':
              isValid = fieldValue.length > 0
              break
            case 'email':
              let emailRegex = new RegExp("^.+@.+")
              isValid = emailRegex.test(fieldValue)
              break

            case 'checkbox':
              break

            case 'radio':
              break

            case 'date':
              break

            case 'time':
              break
          }
          if (isValid) {
            delete newriddorFormErrors[fieldName]
          } else {
            newriddorFormErrors[fieldName] = 'invalid'
          }
        }
      }
    }
    setRiddorFormErrors(newriddorFormErrors)


    // submit the form if everything is valid
    if (Object.keys(newriddorFormErrors).length === 0) {
      //console.log('submit')
      setMode('saving')
      submitForm()
    }


  }


  const setIsReportable = (isReportable: boolean) => {
    //setMode('saving')
    const payload = JSON.stringify({
      "action": "incident",
      "qrCodeIdentifier": reportData!.qrCodeIdentifier,
      "Id": Id,
      "isRiddorReportable": {
        "value": isReportable,
        "timestamp": `${Date.now()}`,
        "name": userData!.name,
        "cognito:username": userData!['cognito:username']
      }
    })
    sendMessageToWebsocket(payload)

    const unsubscribe = subscribe("incidentRiddorResponse", data => {
      console.log('🔆 response received!')
      setMode('showdetails')
      //navigate(`/incidents`)
      unsubscribe()
    })
  }



  const submitForm = async () => {
    setMode('saving')
    const payload = JSON.stringify({
      "action": "incident",
      "qrCodeIdentifier": reportData!.qrCodeIdentifier,
      "Id": Id,
      "riddorFormInputs": riddorFormInputs
    })
    sendMessageToWebsocket(payload)

    const unsubscribe = subscribe("incidentRiddorResponse", data => {
      console.log('🔆 response received!')
      setMode('showdetails')
      //navigate(`/incidents`)
      unsubscribe()
    })
  }


  const submitNote = async () => {
    setMode('saving')
    const payload = JSON.stringify({
      "action": "incident",
      "qrCodeIdentifier": reportData!.qrCodeIdentifier,
      "Id": Id,
      "note": {
        "content": noteContent,
        "timestamp": `${Date.now()}`,
        "name": userData!.name,
        "cognito:username": userData!['cognito:username']
      }
    })
    sendMessageToWebsocket(payload)

    const unsubscribe = subscribe("incidentRiddorResponse", data => {
      console.log('🔆 response received!')
      setMode('showdetails')
      //navigate(`/incidents`)
      unsubscribe()
    })
  }

  const deleteNote = async (noteKey: string) => {
    setMode('saving')
    const payload = JSON.stringify({
      "action": "incident",
      "qrCodeIdentifier": reportData!.qrCodeIdentifier,
      "Id": Id,
      "noteToDelete": noteKey
    })
    sendMessageToWebsocket(payload)

    const unsubscribe = subscribe("incidentRiddorResponse", data => {
      console.log('🔆 response received!')
      setMode('showdetails')
      //navigate(`/incidents`)
      unsubscribe()
    })
  }




  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>

      <div className={`w-full flex flex-row justify-between gap-5`}>
        <h2 className={`text-4xl font-bold text-brandblue `}>
          Incident report</h2>
        <Link
          className={`bg-brandblue text-white font-bold py-2 px-4 rounded hover:opacity-90`}
          to={`/incidents`}
        >
          Back to incidents list
        </Link>
      </div>



      {mode === 'saving' && <Spinner><p>Updating...</p></Spinner>}

      {mode === 'loading' &&
        <Spinner><p>Loading...</p></Spinner>
      }


      {mode === 'notfound' && <p>Sorry, could not find the incident with this ID</p>}

      {mode === 'showdetails' &&


        <div className={`flex flex-col md:flex-row gap-4`}>

          <div className={`flex flex-col gap-4`}>


            <div className={`p-4 bg-white border border-gray-200 rounded hover:opacity-90-lg shadow w-full`}>

              <h4 className={`text-xl font-bold text-brandblue `}>{riddorForm.label}</h4>
              <p>{riddorForm.note}</p>


              {!reportData.IsRiddorReportable && <div>
                <h4 className={`pt-4 pb-2 text-md font-bold text-brandblue `}>Is this incident RIDDOR reportable?</h4>
                <div
                  className={`flex flex-row gap-2`}>
                  <div onClick={() => { setIsReportable(true) }}
                    className={`bg-red-100 hover:bg-red-200 rounded hover:opacity-90-md p-2 text-red-700 font-bold border-2 border-red-200 cursor-pointer`}
                  >Reportable</div>
                  <div onClick={() => { setIsReportable(false) }}
                    className={`bg-green-100 hover:bg-green-200 rounded hover:opacity-90-md p-2 text-green-700 font-bold border-2 border-green-300 cursor-pointer`}
                  >Not Reportable</div>
                </div>
              </div>}

              {reportData.IsRiddorReportable && JSON.parse(reportData.IsRiddorReportable).value === false && <div>
                <p className={`mt-3 mb-3 bg-green-100 rounded hover:opacity-90-md p-2 text-green-700 font-bold border-2 border-green-200`}>This incident was marked as not RIDDOR reportable by {JSON.parse(reportData.IsRiddorReportable).name} at  {getDateFromTimestamp(JSON.parse(reportData.IsRiddorReportable).timestamp)}

                </p>
              </div>}

              {reportData.IsRiddorReportable && JSON.parse(reportData.IsRiddorReportable).value === true && <div>
                <div className={`mt-3 mb-3 bg-red-100 rounded hover:opacity-90-md p-2 text-red-700 font-bold border-2 border-red-200`}>This incident was marked as RIDDOR reportable by {JSON.parse(reportData.IsRiddorReportable).name} at  {getDateFromTimestamp(JSON.parse(reportData.IsRiddorReportable).timestamp)}

                </div>
              </div>}



              {!reportData.RiddorFormDetails && reportData.IsRiddorReportable && JSON.parse(reportData.IsRiddorReportable).value === true && <div>

                {riddorForm.fields.map((field: any, index: number) => {
                  const fullFieldName = `riddor-${field.name}`
                  return <div key={index}>

                    {field.type === 'text' && (
                      <TextField
                        field={field}
                        fullFieldName={fullFieldName}
                        riddorFormInputs={riddorFormInputs}
                        handleChange={handleChange}
                        riddorFormErrors={riddorFormErrors}
                      />
                    )}
                    {field.type === 'email' && (
                      <EmailField
                        field={field}
                        fullFieldName={fullFieldName}
                        riddorFormInputs={riddorFormInputs}
                        handleChange={handleChange}
                        riddorFormErrors={riddorFormErrors}
                      />
                    )}
                    {field.type === 'date' && (
                      <DateField
                        field={field}
                        fullFieldName={fullFieldName}
                        riddorFormInputs={riddorFormInputs}
                        handleChange={handleChange}
                        riddorFormErrors={riddorFormErrors}
                      />
                    )}
                  </div>
                })}
                <div className={`cursor-pointer mt-5 bg-firstaidgreen hover:bg-green-600 text-white font-bold py-2 px-4 rounded hover:opacity-90 flex justify-center text-2xl`}
                  onClick={(e) => validate(e)}
                >
                  Finish
            </div>
              </div>}



              {reportData.RiddorFormDetails && <>


                {riddorForm.fields.map((field: any, index: number) => {
                  const fullFieldName = `riddor-${field.name}`
                  return <div key={index} className={`w-full mb-2`}>
                    {JSON.parse(reportData.RiddorFormDetails)[fullFieldName] && <div>
                      <p className={`font-bold`}>{field.label}:</p>
                      <p>{JSON.parse(reportData.RiddorFormDetails)[fullFieldName]}</p>
                    </div>}
                  </div>
                })} </>}


            </div>



            <div className={`p-4 bg-white border border-gray-200 rounded hover:opacity-90-lg shadow w-full`}>
              {formSections.map((section: any, index: number) => {
                return <div
                  key={index}
                  className={`${(index + 1) < formSections.length && 'border-b border-gray-400 pb-4 mb-4'} w-full `}
                >


                  <h4 className={`text-xl font-bold text-brandblue `}>{section.sectionNumber}.  {section.label}</h4>

                  {section.fields.map((field: any, index: number) => {
                    const fullFieldName = `section${section.sectionNumber}-${field.name}`
                    return <div key={index} className={`w-full mb-2`}>
                      {reportData.report[fullFieldName] && <div>
                        <p className={`font-bold`}>{field.label}:</p>
                        <p>{reportData.report[fullFieldName].value}</p>
                      </div>}
                    </div>
                  })}


                </div>
              })}
            </div>






          </div>
          <div className={`flex flex-col gap-4 md:w-1/3`}>
            <h4 className={`text-xl font-bold text-brandblue `}>Notes</h4>

            {!showAddNote && <button
              className={`bg-brandblue text-white font-bold py-2 px-4 rounded hover:opacity-90 text-center`}
              onClick={() => setShowAddNote(true)}
            >
              Add new
        </button>}

            {showAddNote && <div className={`p-4 bg-white border border-gray-200 rounded hover:opacity-90-lg shadow w-full`}>
              <h5 className={`text-md font-bold text-brandblue `}>Add a new note</h5>
              <textarea
                className={`mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                onChange={(e) => setNoteContent(e.target.value)}
                value={`${noteContent ? noteContent : ''}`}
              />

              <div className={`flex flex-row gap-4`}><button
                className={`bg-gray-400 text-gray-800  font-bold py-2 px-4 rounded hover:opacity-90`}
                onClick={() => setShowAddNote(false)}
              >
                Cancel
        </button>
                <button
                  className={`${noteContent ? 'bg-brandmidblue text-white' : 'bg-gray-400 text-gray-800'}   font-bold py-2 px-4 rounded hover:opacity-90`}
                  disabled={noteContent ? false : true}
                  onClick={submitNote}
                >
                  Save
        </button>
              </div>
            </div>}

            {Id && sortedNoteKeys.map((noteKey, index) => {
              return <Note
                key={index}
                userData={userData}
                tableData={tableData}
                Id={Id}
                noteKey={noteKey}
                deleteNote={deleteNote}
              />
            })}


          </div>

        </div>


      }


    </div>
  </div>
}
export default SingleIncident