export const sortQuestionIdsByOrder = (exampleList) => {
  // Extract the question IDs and their QuestionOrder
  let questions = []
  for (let key in exampleList) {
      if (exampleList.hasOwnProperty(key)) {
          let questionOrder = exampleList[key].QuestionOrder
          if (questionOrder !== undefined && !isNaN(parseInt(questionOrder, 10))) {
              questionOrder = parseInt(questionOrder, 10) // Convert to integer for proper sorting
          } else {
              questionOrder = undefined // Mark as undefined if not a valid integer
          }
          questions.push({ id: key, order: questionOrder })
      }
  }

  // Sort the questions
  questions.sort((a, b) => {
      if (a.order === undefined) return 1
      if (b.order === undefined) return -1
      return a.order - b.order
  })

  // Extract the sorted question IDs
  let sortedQuestionIds = questions.map(q => q.id)

  // Return the sorted array of question IDs
  return sortedQuestionIds
}