import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import Button from '../formfields/Button'
import Card from '../ui-elements/Card'

type Props = {
  title: string
  value: any
  link: string
  showEditButton?: boolean
}


function SummaryItem({
  title,
  value,
  link,
  showEditButton = true
}: Props) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields
  } = useContext(GlobalContext)

  const { checklistId } = useParams()


  return <div className={`
  ${value ? ` bg-gray-300 ` : ` bg-red-100 `}
    flex flex-row w-full gap-4 justify-between items-center rounded px-2 py-1 text-sm
    `}
  // onClick={() => navigate(`${link}`)}
  >
    <div className={`flex flex-row gap-2 justify-start items-center`}>
      <p className={`font-bold`}>{title}:</p>
      {value && <p>{value}</p>}
      {!value && <img src={`/red-alert-icon.png`} className={`w-5 h-5`} alt={''} />}
    </div>

    {showEditButton && <Button
      internalLinkUrl={`${link}`}
      text={`Edit`}
      variant={`primary`}
      size={`small`}
      fullwidth={false}
    />}

  </div>
}
export default SummaryItem