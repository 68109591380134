
import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Button from '../formfields/Button'
import Modal from '../ui-elements/Modal'

type Props = {
}


function SubscriptionExpired({
}: Props) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  return <Modal>
    <div className={`mb-3 flex flex-col items-center justify-center text-center`}>
      <img src={`/red-alert-icon.png`} className={`mb-2 w-12`} alt='' />
      <h2 className={`text-2xl font-bold font-righteous text-red-600`}>
        Your subscription has expired</h2>
      <p>You can add payment details on your ChecQR dashboard.</p>
    </div>

<Button
  externalLinkUrl={`https://dashboard.checqr.net/payments`}
  text={`Add payment method`}
  variant={`warning`}
  size={'big'}
/>


  </Modal>
}

export default SubscriptionExpired