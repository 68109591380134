import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import SummaryItem from './SummaryItem'
import SummaryCheckWindow from './SummaryCheckWindow'
import IndividualQuestion from './IndividualQuestion'
import { startNewQuestion } from './checklist-utils/startNewQuestion'
import { sortQuestionIdsByOrder } from './checklist-utils/sortQuestionIdsByOrder'

type Props = {
  questionsData: null | ObjectStringKeyAnyValue
  showEditButton?: boolean
  savingProgress: boolean
  setSavingProgress: React.Dispatch<React.SetStateAction<any>>
}

function SettingsDetails({
  questionsData,
  showEditButton = true,
  savingProgress,
  setSavingProgress
}: Props) {



  const { checklistId } = useParams()
  const {
      tableData,
    checklistQuestionFields,
    setChecklistQuestionFields
  } = useContext(GlobalContext)

  const navigate = useNavigate()

  const [sortedQuestionIds, setSortedQuestionIds] = useState<null | Array<string>>(null)

  useEffect(() => {
    if (questionsData) {
      const getSortedQuestionIds = sortQuestionIdsByOrder(questionsData)
      // console.log(getSortedQuestionIds)
      if (getSortedQuestionIds) {
        setSortedQuestionIds(getSortedQuestionIds)
      }
    }
  }, [questionsData])



  return <div>

    <Card>
      <div className={`w-full flex flex-row gap-4 items-center justify-between`}>
        <div>
          <h4 className={`font-righteous text-xl font-brandblue mb-4`}>
            Questions
          </h4>
          {sortedQuestionIds && <p>{Object.keys(sortedQuestionIds).length} questions</p>}
        </div>
        <Button
          onClick={() => startNewQuestion(checklistId, setChecklistQuestionFields, navigate)}
          text={`Add question`}
          variant={`primary`}
          // size={`small`}
          fullwidth={false}
        />
      </div>

      {(!sortedQuestionIds || Object.keys(sortedQuestionIds).length === 0) && <p>No questions yet</p>}

      {questionsData && sortedQuestionIds && Object.keys(sortedQuestionIds).length > 0 && <div className={`w-full flex flex-col gap-2`}>
        {sortedQuestionIds.map((questionId, index) => {
          if (questionsData[questionId]) {
            return <IndividualQuestion
              key={index}
              isFirst={index === 0 ? true : false}
              isLast={index + 1 === Object.keys(sortedQuestionIds).length ? true : false}
              questionId={questionId}
              question={questionsData[questionId]}
              savingProgress={savingProgress}
              setSavingProgress={setSavingProgress}
            />
          }
        })}
      </div>}

    </Card>

  </div>
}
export default SettingsDetails