import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import Spinner from '../ui-elements/Spinner'
import { useParams, useNavigate } from 'react-router-dom'
import DeleteButton from '../formfields/DeleteButton'
import ListOfChecklists from './ListOfChecklists'
import ChecklistWrapper from './wrapper/ChecklistWrapper'

type Props = {
}

function MyChecklists({
}: Props) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields
  } = useContext(GlobalContext)

  const { checklistId } = useParams()
  const navigate = useNavigate()
  const [savingProgress, setSavingProgress] = useState(false)

  const deleteChecklist = (checklistId: string) => {
    if (checklistId) {
      setSavingProgress(true)
      const payload = JSON.stringify({
        action: "checklists",
        subAction: "deleteChecklist",
        checklistId: checklistId
      })
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("checklistDeletedResponse", data => {
        setSavingProgress(false)
        unsubscribe()
      })
    }
  }


  return <ChecklistWrapper
    title={'My Checklists'}
    // subtitle={''}
    // prevUrl={prevUrl}
    // nextUrl={nextUrl}
    savingProgress={savingProgress}
    setSavingProgress={setSavingProgress}
  >

    <div className={`flex flex-row gap-4 items-center justify-between`}>
      <h3 className={`font-righteous text-3xl font-brandblue`}>My Checklists</h3>
      <Button
        internalLinkUrl={`new/settings`}
        text={`Add new`}
        variant={`primary`}
        // size={`big`}
        fullwidth={false}
      />
    </div>

    <ListOfChecklists />

  </ChecklistWrapper>
}
export default MyChecklists