export function getDateFromTimestamp(timestamp: string){
  const newDate = new Date(parseInt(timestamp))
  const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  const year = newDate.getFullYear()
  const month = months[newDate.getMonth()]
  const date = newDate.getDate()
  const hour = newDate.getHours()
  const min = newDate.getMinutes()
  const sec = newDate.getSeconds()
  const parsedDate = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec
  return parsedDate


}


