export function getStartAndEndTimestampsForDay(dateString, timeZone) {
  // Parse the input date string
  const date = new Date(dateString);

  // Function to format date to YYYY-MM-DD
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Get the start and end of the day in the specified timezone
  const startOfDay = new Date(formatDate(date) + 'T00:00:00');
  const endOfDay = new Date(formatDate(date) + 'T23:59:59.999');

  // Convert startOfDay and endOfDay to the specified timezone
  const startOfDayInTimeZone = new Date(startOfDay.toLocaleString("en-US", { timeZone }));
  const endOfDayInTimeZone = new Date(endOfDay.toLocaleString("en-US", { timeZone }));

  // Get Unix timestamps in milliseconds
  const startTimestamp = startOfDayInTimeZone.getTime();
  const endTimestamp = endOfDayInTimeZone.getTime();

  return {
    startTimestamp: startTimestamp,
    endTimestamp: endTimestamp
  };
}


